import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import { RecentChart } from '../Graphics/Chart';
import Chart from 'react-apexcharts';
import { H3 } from '../../../Themes/Components/AbstractElements';

const LinhasChart = ({ data }) => {
  const [dadosFormatados, setDadosFormatados] = useState([]);
  const [categorias, setCategorias] = useState({});

  useEffect(() => {
    if (data && data.data && data.data.datasets) {
      const formattedData = data.data.datasets
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((dataset) => ({
          seriesName: dataset.label,
          name: dataset.label,
          data: dataset.data,
          yaxis: {
            seriesName: dataset.label,
          },
        }));

      setDadosFormatados(formattedData);
      setCategorias(data.data.labels);
    }
  }, [data]);

  if (dadosFormatados.length === 0) {
    return <></>;
  }

  return (
    <Fragment>
      <Card className='recent-order'>
        <CardHeader className='pb-0'>
          <H3>{data?.titulo}</H3>
        </CardHeader>
        <div className='medium-chart'>
          <div className='recent-chart' id='recent-chart'>
            <Chart
              options={{
                ...RecentChart.options,
                xaxis: {
                  ...RecentChart.options.xaxis,
                  categories: categorias,
                },
              }}
              series={dadosFormatados}
              type='area'
              height={355}
            />
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default LinhasChart;
