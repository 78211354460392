import { useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../Themes/Components/AbstractElements';
import DataTable from 'react-data-table-component';
import { formatValue } from '../../../utils/formatacoes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableActions from '../../../Themes/Components/TableActions';
import { format } from 'date-fns';
import Loading from '../../../Themes/Components/Loading';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import TrackingEmbarquesService from '../../../Services/Tracking/TrackingEmbarquesService';

const TrackingEmbarquesIndex = () => {
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
    total: 0,
    page: 1,
    limit: 20,
    sortColumn: null,
    sortOrder: null,
    filtros: {},
  });
  const [loading, setLoading] = useState(true);
  const [filtrosFixos, setFiltrosFixos] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const index = async () => {
      setLoading(true);
      const handleViewClick = (id) => navigate(`/tracking/embarques/view/${id}`);

      try {
        const data = await TrackingEmbarquesService.index(
          tableData.page,
          tableData.limit,
          tableData.sortColumn,
          tableData.sortOrder,
          tableData.filtros,
        );

        const colunas = [
          {
            name: '',
            id: 'actions',
            center: true,
            compact: true,
            width: '3vw',
            cell: (row) => (
              <i
                className='icofont icofont-eye-alt'
                onClick={() => handleViewClick(row['Embarques.Id'])}
                style={{ fontSize: 20, cursor: 'pointer' }}
                title='Visualizar embarque'
              />
            ),
          },
        ];

        data.columns.forEach((coluna) => {
          const { label, campo, tipo, casasDecimais } = coluna;
          const icons = {
            ship: <i className='icofont icofont-ship' style={{ fontSize: 20 }} title='Marítima' />,
            plane: <i className='icofont icofont-airplane-alt' style={{ fontSize: 20 }} title='Marítima' />,
          };
          if (label == 'status_id') return;

          if (label == 'Modal') {
            colunas.push({
              name: label,
              id: campo,
              sortable: true,
              width: '5vw',
              center: true,
              right: tipo === 'decimal',
              wrap: true,
              selector: (row) => {
                const coluna = `Embarques.${label}`;
                return row[coluna] ? icons[row[coluna]] : '-';
              },
            });
            return;
          }

          colunas.push({
            name: label,
            id: campo,
            sortable: true,
            right: tipo === 'decimal',
            wrap: true,
            selector: (row) => {
              const coluna = `Embarques.${label}`;
              return row[coluna] ? formatValue(row[coluna], tipo, casasDecimais) : '-';
            },
          });
        });

        setTableData({
          columns: colunas,
          rows: data.rows,
          total: data.pageInfo.total,
        });

        setFiltrosFixos(data.filtrosFixos);
      } catch (error) {
        toast.error(error.message);
      }

      setLoading(false);
    };

    index();
  }, [navigate]);

  const handlePageChange = async (page, limit) => {
    try {
      setLoading(true);

      const data = await TrackingEmbarquesService.index(
        page,
        limit,
        tableData.sortColumn,
        tableData.sortOrder,
        tableData.filtros,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        page,
        limit,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };

  const handleSort = async (column, order) => {
    try {
      setLoading(true);

      const data = await TrackingEmbarquesService.index(
        tableData.page,
        tableData.limit,
        column.id,
        order,
        tableData.filtros,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        sortColumn: column.id,
        sortOrder: order,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };

  const handleSubmitFilters = async (values) => {
    if (Object.keys(values).length === 0) {
      toast.warning('Informe os filtros.');
      return;
    }

    const filtros = {};

    Object.keys(values).forEach((campo) => {
      let value = values[campo];

      if (value instanceof Date) {
        value = format(value, 'dd/MM/yyyy');
      }

      if (value.length > 0) {
        filtros[campo] = value;
      }
    });

    setLoading(true);

    try {
      const data = await TrackingEmbarquesService.index(
        tableData.page,
        tableData.limit,
        tableData.sortColumn,
        tableData.sortOrder,
        filtros,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        filtros,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };

  const clearTable = async () => {
    setTableData({
      columns: tableData.columns,
      rows: [],
      total: 0,
      page: 1,
      limit: 20,
      sortColumn: null,
      sortOrder: null,
      filtros: {},
    });
  };

  return (
    <>
      <Breadcrumbs mainTitle='Embarques' title='Embarques' parent='Cadastro' />
      <Container fluid={true}>
        <Row>
          <Col sm={12}>
            <Card>
              <CardHeader className='pb-0'>
                <TableActions
                  dataFilters={filtrosFixos}
                  clearTable={clearTable}
                  showFiltroFavoritos={false}
                  handleFilterSubmit={handleSubmitFilters}
                  title='embarques'
                />
              </CardHeader>
              <CardBody className='mt-0 pt-0 m-b-0'>
                <DataTable
                  noDataComponent='Nenhum registro encontrado.'
                  data={tableData.rows}
                  columns={tableData.columns}
                  striped={true}
                  responsive={true}
                  highlightOnHover={true}
                  progressPending={loading}
                  progressComponent={<Loading />}
                  pagination
                  paginationServer={true}
                  paginationPerPage={tableData.limit}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                    rangeSeparatorText: 'de',
                  }}
                  onChangePage={(page) => handlePageChange(page, tableData.limit)}
                  onChangeRowsPerPage={(limit, page) => handlePageChange(page, limit)}
                  paginationTotalRows={tableData.total}
                  sortServer
                  onSort={handleSort}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TrackingEmbarquesIndex;
