import React, { Fragment, useState, useEffect } from 'react';

const PageLoader = ({ loaded }) => {
  const [show, setShow] = useState('flex');

  useEffect(() => {
    setShow(loaded ? 'flex' : 'none');
  }, [loaded]);

  return (
    <Fragment>
      <div className='loader-wrapper' style={{ display: `${show}` }}>
        <div className='loader'></div>
      </div>
    </Fragment>
  );
};

export default PageLoader;
