import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const FabricanteProdutorTable = ({ fabricantes }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'codigo',
      name: 'País Origem',
      selector: (row) => (row.pais != null ? row.pais.codigo + ' - ' + row.pais.nome : '-'),
    },
    {
      id: 'conhecido',
      name: 'Conhecido',
      selector: (row) => (row.conhecido ? 'Sim' : 'Não'),
    },
    {
      id: 'nome',
      name: 'Nome - TIN',
      selector: (row) => row.operador?.nome ?? '-',
    },
    {
      id: 'vinculado',
      name: 'Vinculado',
      selector: (row) => (row.vinculado ? 'Sim' : 'Não'),
    },
  ];

  useEffect(() => {
    if (!fabricantes) return;

    setRows(fabricantes);
  }, [fabricantes]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default FabricanteProdutorTable;
