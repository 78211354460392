import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate } from '../../../../../utils/formatacoes';

const ConteinerEventosTable = ({ events }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'descricao',
      name: 'Evento',
      selector: (row) => row.descricao ?? '-',
    },
    {
      id: 'data',
      name: 'Data',
      selector: (row) => formatDate(row.data) ?? '-',
    },
  ];

  useEffect(() => {
    if (!events) return;

    setRows(events);
  }, [events]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default ConteinerEventosTable;
