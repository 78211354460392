import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H3 } from '../../../Themes/Components/AbstractElements';
import ReactApexChart from 'react-apexcharts';

const DoughnutChart = ({ data }) => {
  const [dadosFormatados, setDadosFormatados] = useState({
    series: [],
    options: {
      labels: [],
    },
  });

  useEffect(() => {
    if (data && data.data) {
      const dados = {
        series: data.data.data,
        options: {
          labels: data.data.labels,
          colors: data.data.colors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      };

      setDadosFormatados(dados);
    }
  }, [data]);

  return (
    <Fragment>
      <Card>
        <CardHeader className='pb-0'>
          <H3>{data?.titulo}</H3>
        </CardHeader>
        <CardBody>
          <div className='medium-chart'>
            <div className='recent-chart' id='recent-chart'>
              <ReactApexChart
                options={dadosFormatados.options}
                series={dadosFormatados.series}
                type='donut'
                height={355}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DoughnutChart;
