import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Footer, P } from '../../Components/AbstractElements';
import { Link, useLocation } from 'react-router-dom';
import { APP_VERSION } from '../../Config/appConfig';

const FooterClass = (props) => {
  const location = useLocation();

  const redirecionar = () => {
    window.open('https://www.fazcomex.com.br', '_blank');
  };

  return (
    <Fragment>
      <Footer
        attrFooter={{
          className: `footer ${
            location.pathname === '/page-layout/footer-dark'
              ? 'footer-dark'
              : location.pathname === '/page-layout/footer-fixed'
                ? 'footer-fix'
                : ''
          } ${props.className ?? ''}`,
        }}
      >
        <Container fluid={true}>
          <Row className='align-items-center'>
            <Col xs={props.className ? '12' : '10'} className='p-0 footer-center'>
              <P attrPara={{ className: 'mb-0 text-center' }}>
                {`© ${new Date().getFullYear()} - Todos os direitos reservados - Desenvolvido com ❤ por `}
                <Link onClick={() => redirecionar()}>FazComex</Link> - {APP_VERSION}
              </P>
            </Col>
          </Row>
        </Container>
      </Footer>
    </Fragment>
  );
};

export default FooterClass;
