import { Fragment } from 'react';
import { H3, P } from '../../../Themes/Components/AbstractElements';
import { Card, CardBody } from 'reactstrap';
import ConfigDB from '../../../Themes/Config/ThemeConfig';
import { formatNumber } from '../../../utils/formatacoes';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

const TotaisChart = ({ data }) => {
  return (
    <Fragment>
      <Card className='sale-chart'>
        <CardBody>
          <div className='d-flex'>
            <div className='flex-shrink-0'>
              <div className='sale-detail'>
                <div className='icon'>
                  <i
                    className={`icofont icofont-${data.data.icone}`}
                    style={{
                      fontSize: '25px',
                      color: primary,
                    }}
                  ></i>
                </div>
                <div className='sale-content'>
                  <H3>{data.titulo}</H3>
                  <P>
                    {data.data.label && data.data.labelPos === 'start' ? `${data.data.label} ` : ''}
                    {data.data.casasDecimais
                      ? formatNumber(data.data.total, data.data.casasDecimais)
                      : data.data.total}{' '}
                    {data.data.label && data.data.labelPos === 'end' ? ` ${data.data.label}` : ''}
                  </P>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TotaisChart;
