import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LI, H4, UL } from '../../../Themes/Components/AbstractElements';
import { useContext } from 'react';
import CustomizerContext from '../../_helper/Customizer';
import { ArrowDown, ArrowUp, Archive, Home, Globe } from 'react-feather';

const SidebarMenulist = ({ MENUITEMS, t, toggletNavActive }) => {
  const { setClickEvent } = useContext(CustomizerContext);
  const { pathname } = useLocation();
  const [activeMenu, setActiveMenu] = useState();
  const [childActiveMenu, setChildActiveMenu] = useState();
  const [childActiveLink, setChildActiveLink] = useState();
  const [subChildActiveLink, setsubChildActiveLink] = useState('Default');

  useEffect(() => {
    if (pathname) {
      MENUITEMS.filter((childrens) => {
        if (
          childrens.children
            ? childrens.children.filter((path) => {
                if (path.path) {
                  if (pathname === path.path) {
                    setsubChildActiveLink(path.label);
                    return true;
                  }
                  return false;
                } else {
                  if (
                    path.children &&
                    path.children.filter((child) => {
                      if (child.path === pathname) {
                        setChildActiveLink(child.label);
                        return true;
                      }
                      return false;
                    }).length > 0
                  ) {
                    setChildActiveMenu(path.label);
                    return true;
                  }
                  return false;
                }
              }).length > 0
            : childrens.path === pathname && setActiveMenu(childrens.label)
        ) {
          setActiveMenu(childrens.label);
          return true;
        }
        return false;
      });
    }
  }, [pathname, MENUITEMS]);

  return (
    <Fragment>
      {MENUITEMS.map((menuItem, i) => (
        <LI attrLI={{ className: 'sidebar-list simple-list hoverd' }} key={i}>
          <a
            href='javascript'
            className={`sidebar-link sidebar-title ${menuItem.label === activeMenu ? 'active' : ''}`}
            onClick={(event) => {
              event.preventDefault();
              setActiveMenu(menuItem.label !== activeMenu && menuItem.label);
              toggletNavActive();
            }}
          >
            <i className='fa fa-thumbs-o-up txt-primary'></i>
            {menuItem.icone === 'ArrowUp' && <ArrowUp />}
            {menuItem.icone === 'ArrowDown' && <ArrowDown />}
            {menuItem.icone === 'Archive' && <Archive />}
            {menuItem.icone === 'Home' && <Home />}
            {menuItem.icone === 'Globe' && <Globe />}
            <span className='lan-3 m-l-5'>{t(menuItem.label)}</span>
            <div className='according-menu'>
              {menuItem.label === activeMenu ? (
                <i className='fa fa-angle-down me-0'></i>
              ) : (
                <i className='fa fa-angle-right me-0'></i>
              )}
            </div>
          </a>

          {menuItem.type === 'link' && (
            <Link
              to={menuItem.path}
              className={`sidebar-link menu-title ${menuItem.label === activeMenu ? 'active' : ''}`}
              onClick={() => {
                setActiveMenu(menuItem.label);
                toggletNavActive();
                setClickEvent((pre) => !pre);
              }}
            >
              <span>{menuItem.label}</span>
            </Link>
          )}
          {menuItem.children && (
            <UL
              attrUL={{
                className: `simple-list sidebar-submenu ${menuItem.label === activeMenu ? 'd-block' : 'd-none'}`,
              }}
            >
              {menuItem.children.map((childrenItem, index) => {
                return (
                  <LI key={index}>
                    <Link
                      to={childrenItem.path}
                      className={`${childrenItem.label === subChildActiveLink ? 'active' : ''}`}
                      onClick={() => {
                        setsubChildActiveLink(childrenItem.label);
                        toggletNavActive();
                        setClickEvent((pre) => !pre);
                      }}
                    >
                      {t(childrenItem.label)}
                    </Link>
                    {childrenItem.children && (
                      <UL
                        attrUL={{
                          className: 'simple-list nav-sub-childmenu submenu-content',
                          style: {
                            display: `${childrenItem.label === childActiveMenu ? 'block' : 'none'}`,
                          },
                        }}
                      ></UL>
                    )}
                  </LI>
                );
              })}
            </UL>
          )}
        </LI>
      ))}
    </Fragment>
  );
};
export default SidebarMenulist;
