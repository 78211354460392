import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate } from '../../../../../utils/formatacoes';

const LpcosTable = ({ lpcos, situacoes }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      name: 'Número',
      selector: (row) => row.exportacao_lpco?.numero ?? '-',
    },
    {
      name: 'Órgão',
      selector: (row) => row.exportacao_lpco?.orgao?.nome ?? '-',
    },
    {
      name: 'Modelo',
      selector: (row) =>
        row.exportacao_lpco?.modelo
          ? `${row.exportacao_lpco?.modelo.codigo_modelo} - ${row.exportacao_lpco?.modelo.nome}`
          : '-',
    },
    {
      name: 'Situação',
      selector: (row) =>
        situacoes && row.exportacao_lpco?.situacao_id ? situacoes[row.exportacao_lpco?.situacao_id] : '-',
    },
    {
      name: 'Data de Registro',
      selector: (row) => formatDate(row.exportacao_lpco?.data_registro, false) ?? '-',
    },
  ];

  useEffect(() => {
    if (lpcos) {
      setRows(lpcos);
    }
  }, [lpcos]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default LpcosTable;
