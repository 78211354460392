import React, { Fragment, useEffect, useState } from 'react';
import { Image, LI, P, UL } from '../../../../Themes/Components/AbstractElements';
import { User, Mail, FileText, LogOut } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../../../../Services/Auth/authService';

const LogoutClass = () => {
  const Navigate = useNavigate();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || '');
    const username = authService.getUserName();
    setName(username);
  }, [setProfile, setName]);

  const Logout = () => {
    authService.logout();
    Navigate(`/login`);
  };

  return (
    <Fragment>
      <LI attrLI={{ className: 'profile-nav onhover-dropdown p-0' }}>
        <div className='d-flex profile-media'>
          <Image
            attrImage={{
              className: 'b-r-50 m-0',
              src: profile,
              alt: '',
            }}
          />
          <div className='flex-grow-1'>
            <span>{name}</span> <i className='middle icofont icofont-simple-down'></i>
          </div>
        </div>
        <UL attrUL={{ className: 'profile-dropdown onhover-show-div' }}>
          <LI attrLI={{ onClick: Logout }}>
            <Link to={`/login`}>
              <LogOut />
              Sair
            </Link>
          </LI>
        </UL>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;
