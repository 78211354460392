import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { ArrowDown, ArrowUp, Search } from 'react-feather';
import { Col, Input } from 'reactstrap';
import CustomizerContext from '../../../_helper/Customizer';
import ListOfMenu from '../RightBar/Bookmark/ListOfMenu';
import EmpltyClass from '../RightBar/Bookmark/EmptyClass';
import ConfiguracaoUsuariosService from '../../../../Services/Configuracao/ConfiguracaoUsuariosService';

const Searchbar = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchIcon, setSearchIcon] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchMessage, setSearchMessage] = useState('');
  const { setIsClose } = useContext(CustomizerContext);
  const [IsOpen, setIsOpen] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSearchValue('');
      setSearchResult([]);
      setIsClose(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction, searchValue]);

  const addFix = () => {
    setSearchToggle(true);
  };

  const removeFix = useCallback(() => {
    setSearchValue('');
    setSearchToggle(false);
    setIsClose(false);
  }, []);

  useEffect(() => {
    if (!IsOpen) {
      setSearchValue('');
      setSearchToggle(false);
      setIsClose(false);
    }
  }, [IsOpen]);

  const handleSearchKeyword = async (keyword) => {
    keyword ? addFix() : removeFix();
    setSearchValue(keyword);

    let menuItens = {
      importacaoProcessos: {
        label: 'Processos de Importação',
        icon: ArrowUp,
        items: [],
      },
      exportacaoProcessos: {
        label: 'Processos de Exportação',
        icon: ArrowDown,
        items: [],
      },
    };

    if (keyword.length >= 4) {
      const data = await ConfiguracaoUsuariosService.buscar(keyword);
      const isEmpty = Object.keys(data).length === 0;

      if (!isEmpty) {
        setSearchIcon(false);
        setSearchMessage('');

        Object.keys(data).map((path) => {
          const title = data[path];
          const isExpo = /exportacao/.test(path);

          const menuItem = {
            path,
            title,
          };

          if (isExpo) {
            menuItens.exportacaoProcessos.items.push(menuItem);
          } else {
            menuItens.importacaoProcessos.items.push(menuItem);
          }
        });

        if (menuItens.exportacaoProcessos.items.length === 0) {
          delete menuItens.exportacaoProcessos;
        }

        if (menuItens.importacaoProcessos.items.length === 0) {
          delete menuItens.importacaoProcessos;
        }

        setSearchResult(menuItens);
      } else {
        setSearchMessage('Não foram encontrados registros para exibir.');
        setSearchIcon(true);
        setSearchResult(null);
      }
    } else if (keyword.length > 0) {
      setSearchMessage('Digite ao menos 3 caracteres para buscar.');
      setSearchIcon(true);
      setSearchResult(null);
    } else {
      setSearchIcon(false);
      setSearchResult(null);
      setIsOpen(false);
    }
  };

  return (
    <Fragment>
      <Col className='left-header horizontal-wrapper ps-0'>
        <div className={`input-group search-form ${IsOpen ? 'open' : ''}`}>
          <Input
            type='text'
            placeholder='Busque processos por sua referência'
            value={searchValue}
            onChange={(e) => {
              handleSearchKeyword(e.target.value);
            }}
          />
          <span className='input-group-text mobile-search'>
            <Search
              onClick={() => {
                setIsOpen(!IsOpen);
              }}
            />
          </span>
          <ListOfMenu
            searchValue={searchValue}
            searchResult={searchResult}
            removeFix={removeFix}
            searchBar={true}
            searchToggle={searchToggle}
          />
          <EmpltyClass searchIcon={searchIcon} search={true} message={searchMessage} />
        </div>
      </Col>
    </Fragment>
  );
};
export default Searchbar;
