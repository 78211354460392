import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import authService from '../Services/Auth/authService'; // Verifique se o caminho do arquivo está correto

const PrivateRoute = () => {
  const token = authService.getToken();

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  const checkTokenExpiration = () => {
    const expirationTime = localStorage.getItem('loginTime');
    if (expirationTime) {
      const currentTime = new Date().getTime();
      if (currentTime >= parseInt(expirationTime, 10)) {
        logoutAndRedirect();
      }
    }
  };

  const logoutAndRedirect = () => {
    authService.logout();
    window.location.href = '/login';
  };

  return token ? <Outlet /> : <Navigate to='/login' />;
};

export default PrivateRoute;
