import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LoginTab from './index';
import { Image } from '../../Themes/Components/AbstractElements';
import ConfiguracaoUsuariosService from '../../Services/Configuracao/ConfiguracaoUsuariosService';
import { LOGOTIPO_EMPRESA, EXPIRATION_TIME } from '../../Themes/Config/appConfig';
import FooterClass from '../../Themes/Layout/Footer';

const Logins = () => {
  const [logotipo, setLogotipo] = useState(null);

  useEffect(() => {
    const setItemWithExpiry = (key, value, expiration) => {
      const now = new Date().getTime();
      const item = { value, expiry: now + expiration };
      localStorage.setItem(key, JSON.stringify(item));
    };

    const getItemWithExpiry = (key) => {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) return null;

      let item;
      try {
        item = JSON.parse(itemStr);
      } catch (e) {
        return null;
      }

      const now = new Date().getTime();
      if (now > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    };

    const getLogotipo = async () => {
      try {
        const url = window.location.hostname;
        const data = await ConfiguracaoUsuariosService.logotipo(url);
        if (data.logotipo != '') {
          setLogotipo(data.logotipo);
          setItemWithExpiry(LOGOTIPO_EMPRESA, data.logotipo, EXPIRATION_TIME);
        } else {
          setLogotipo(require('../../Themes/assets/vendor/localhost.png'));
        }
      } catch (error) {
        console.log(error);
      }
    };

    const logoSalvo = getItemWithExpiry(LOGOTIPO_EMPRESA);
    if (logoSalvo) {
      setLogotipo(logoSalvo);
    } else {
      getLogotipo();
    }
  }, []);

  return (
    <Container fluid={true} className='p-0'>
      <Row className='m-0'>
        <Col xs='12' className='p-0'>
          <div className='login-card auth-login'>
            <div>
              <div className='logo'>
                <Image
                  attrImage={{
                    className: 'img-fluid for-light logoImage',
                    src: logotipo,
                    alt: 'logo image',
                  }}
                />
              </div>
              <div className='login-main login-tab'>
                <LoginTab />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <FooterClass className='footer-login' />
    </Container>
  );
};

export default Logins;
