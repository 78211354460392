import { api } from '../api';

const ConfiguracaoUsuariosService = {
  getDashboard: async (plugin) => {
    try {
      const response = await api.get(`configuracao/usuarios/dashboard/${plugin}`);

      return response.data;
    } catch (error) {
      throw new Error('Falha ao obter o dashboard');
    }
  },

  getGrafico: async (id) => {
    try {
      const response = await api.get(`configuracao/usuarios/grafico/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Falha ao obter o gráfico');
    }
  },

  buscar: async (palavra) => {
    try {
      const response = await api.get(`configuracao/usuarios/buscar/${palavra}`);
      return response.data;
    } catch (error) {
      throw new Error('Falha ao obter os resultados da busca.');
    }
  },

  setFavorito: async (model, processoId, ativo) => {
    try {
      const response = await api.post(`configuracao/usuarios/setProcessoFavorito`, {
        model,
        processoId,
        ativo,
      });
      return response.data;
    } catch (error) {
      throw new Error('Não foi possível salvar como favorito.');
    }
  },

  logotipo: async (url) => {
    try {
      const response = await api.get(`/configuracao/usuarios/logotipo?urlPainel=${url}`);
      return response.data;
    } catch (error) {
      throw new Error('Não foi possível carregar o logotipo.');
    }
  },
};

export default ConfiguracaoUsuariosService;
