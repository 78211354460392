import DashboardExportacao from '../Modules/Dashboard/Exportacao/index';
import DashboardImportacao from '../Modules/Dashboard/Importacao/index';
import ExportacaoProcessosIndex from '../Modules/Exportacao/Processos';
import ExportacaoProcessosView from '../Modules/Exportacao/Processos/view';
import ImportacaoProcessosIndex from '../Modules/Importacao/Processos';
import ImportacaoProcessosView from '../Modules/Importacao/Processos/view';
import ImportacaoProcessosRelatorios from '../Modules/Importacao/Processos/relatorios';
import ExportacaoProcessosRelatorios from '../Modules/Exportacao/Processos/relatorios';
import CadastroProdutosIndex from '../Modules/Cadastro/Produtos';
import CadastroProdutosView from '../Modules/Cadastro/Produtos/view';
import CadastroProdutosEdit from '../Modules/Cadastro/Produtos/edit';
import TrackingEmbarquesIndex from '../Modules/Tracking/Embarques';
import TrackingEmbarquesView from '../Modules/Tracking/Embarques/view';

export const routes = [
  // dashboard
  { path: `/dashboards/exportacao`, Component: <DashboardExportacao /> },
  { path: `/dashboards/importacao`, Component: <DashboardImportacao /> },

  // exportação
  { path: `/exportacao/processos/`, Component: <ExportacaoProcessosIndex /> },
  { path: `/exportacao/processos/view/:id`, Component: <ExportacaoProcessosView /> },
  { path: `/exportacao/processos/relatorios`, Component: <ExportacaoProcessosRelatorios /> },

  // importação
  { path: `/importacao/processos/`, Component: <ImportacaoProcessosIndex /> },
  { path: `/importacao/processos/view/:id`, Component: <ImportacaoProcessosView /> },
  { path: `/importacao/processos/relatorios`, Component: <ImportacaoProcessosRelatorios /> },

  // cadastro
  { path: `/cadastro/produtos/`, Component: <CadastroProdutosIndex /> },
  { path: `/cadastro/produtos/view/:id`, Component: <CadastroProdutosView /> },
  { path: `/cadastro/produtos/edit/:id`, Component: <CadastroProdutosEdit /> },

  // tracking
  { path: `/tracking/embarques/`, Component: <TrackingEmbarquesIndex /> },
  { path: `/tracking/embarques/view/:id`, Component: <TrackingEmbarquesView /> },
];
