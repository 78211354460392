import React, { Fragment, useContext } from 'react';
import { Image } from '../../../Themes/Components/AbstractElements';
import { AlignLeft } from 'react-feather';
import CustomizerContext from '../../_helper/Customizer';
import { Link } from 'react-router-dom';
import { LOGOTIPO_CLIENTE, DASHBOARD_INICIAL } from '../../Config/appConfig';

const SidebarLogo = () => {
  const { toggleSidebar, toggleIcon } = useContext(CustomizerContext);
  const mixBackgroundLayout = localStorage.getItem('mix_background_layout');
  const logotipoCliente = localStorage.getItem(LOGOTIPO_CLIENTE);
  const dashboardInicial = localStorage.getItem(DASHBOARD_INICIAL);

  let logoImageSrc = '';
  let logoImageAlt = 'Logotipo';

  if (logotipoCliente) {
    logoImageSrc = logotipoCliente;
  } else if (mixBackgroundLayout === 'light-only' || !mixBackgroundLayout) {
    logoImageSrc = require('../../assets/vendor/localhost.png');
  } else {
    logoImageSrc = require('../../assets/vendor/localhost-painel.png');
  }

  return (
    <Fragment>
      <div className='logo-wrapper'>
        <Link to={dashboardInicial}>
          <Image
            attrImage={{
              className: 'img-fluid',
              src: logoImageSrc,
              alt: logoImageAlt,
            }}
          />
        </Link>
        <div className='back-btn' onClick={() => toggleSidebar(!toggleIcon)}>
          <i className='fa fa-angle-left'></i>
        </div>
        <div className='toggle-sidebar m-t-5' onClick={() => toggleSidebar(!toggleIcon)}>
          <AlignLeft className={`status_toggle middle`} id='sidebar-toggle' />
        </div>
      </div>
    </Fragment>
  );
};

export default SidebarLogo;
