import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatNumber } from '../../../../../utils/formatacoes';

const ImpostosTable = ({ processoData }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'descricao',
      name: '',
      selector: (row) => row.descricao,
    },
    {
      id: 'valorCalculado',
      name: 'Calculado R$',
      right: true,
      selector: (row) => formatNumber(row.valorCalculado) ?? '-',
    },
    {
      id: 'valorDevido',
      name: 'Devido R$',
      right: true,
      selector: (row) => formatNumber(row.valorDevido) ?? '-',
    },
  ];

  useEffect(() => {
    if (!processoData) return;

    const linhas = [
      {
        descricao: 'II',
        valorCalculado: processoData.totais.ii_vlr_calculado,
        valorDevido: processoData.totais.ii_vlr_devido,
      },
      {
        descricao: 'IPI',
        valorCalculado: processoData.totais.ipi_vlr_calculado,
        valorDevido: processoData.totais.ipi_vlr_devido,
      },
      {
        descricao: 'PIS',
        valorCalculado: processoData.totais.pis_vlr_calculado,
        valorDevido: processoData.totais.pis_vlr_devido,
      },
      {
        descricao: 'COFINS',
        valorCalculado: processoData.totais.cofins_vlr_calculado,
        valorDevido: processoData.totais.cofins_vlr_devido,
      },
      {
        descricao: 'Dumping',
        valorCalculado: processoData.totais.dumping_vlr_devido,
        valorDevido: processoData.totais.dumping_vlr_devido,
      },
    ];

    processoData.processo_outrasdespesas.forEach((despesa) => {
      const { nome, valor } = despesa;

      linhas.push({
        descricao: nome,
        valorCalculado: valor,
        valorDevido: valor,
      });
    });

    linhas.push(
      {
        descricao: 'Taxa Siscomex',
        valorCalculado: processoData.totais.taxa_siscomex,
        valorDevido: processoData.totais.taxa_siscomex,
      },
      {
        descricao: 'ICMS Isento/Exonerado',
        valorCalculado: processoData.totais.icms_valor_isento,
        valorDevido: processoData.totais.icms_valor_isento,
      },
      {
        descricao: 'ICMS Devido/Calculado',
        valorCalculado: processoData.totais.icms_valor_devido,
        valorDevido: processoData.totais.icms_valor_devido,
      },
      {
        descricao: 'ICMS Total',
        valorCalculado: processoData.totais.icms_valor,
        valorDevido: processoData.totais.icms_valor,
      },
    );

    setRows(linhas);
  }, [processoData]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} />
    </Fragment>
  );
};

export default ImpostosTable;
