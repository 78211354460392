import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { H5 } from '../../../../../Themes/Components/AbstractElements';

const MenuItemsGroup = ({ group, removeFix }) => {
  const GroupIcon = group.icon;

  return (
    <Fragment>
      <div className='d-flex'>
        <div>
          <GroupIcon
            style={{
              width: 20,
              height: 20,
            }}
          />
        </div>
        <div className='pt-1 ms-1'>
          <H5>{group.label}</H5>
        </div>
      </div>

      {group.items &&
        group.items.map((data, index) => {
          return (
            <div className='ProfileCard u-cf' key={index}>
              <div className='ProfileCard-details'>
                <div className='ProfileCard-realName'>
                  <Link to={data.path} className='realname' onClick={removeFix}>
                    {data.title}
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </Fragment>
  );
};

export default MenuItemsGroup;
