import React, { Fragment, useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import SidebarMenuItems from './SidebarMenuItems';

const SidebarMenu = ({ width }) => {
  const [rightArrow, setRightArrow] = useState(false);
  const [leftArrow, setLeftArrow] = useState(false);
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    setLeftArrow(true);
  }, []);
  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      setRightArrow(true);
      setLeftArrow(false);
    } else {
      setLeftArrow(false);
      setMargin((margin) => (margin += -width));
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      setLeftArrow(true);
      setRightArrow(false);
    } else {
      setMargin((margin) => (margin += width));
      setRightArrow(false);
    }
  };

  return (
    <Fragment>
      <nav className='sidebar-main'>
        <div className={`left-arrow ${leftArrow ? 'd-none' : ''}`} id='left-arrow' onClick={scrollToLeft}>
          <ArrowLeft />
        </div>
        <div id='sidebar-menu'>
          <SidebarMenuItems margin={margin} />
        </div>
        <div className={`right-arrow ${rightArrow ? 'd-none' : ''}`} onClick={scrollToRight}>
          <ArrowRight />
        </div>
      </nav>
    </Fragment>
  );
};
export default SidebarMenu;
