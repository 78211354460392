import React, { useState, useEffect } from 'react';
import { H4, Span } from '../../../../Themes/Components/AbstractElements';
import Atributo from './atributo';

const AtributosNCM = ({ produtoData, tipo, updateDadosEditados, isEditMode }) => {
  const [atributos, setAtributos] = useState([]);

  useEffect(() => {
    if (!produtoData) return;

    const deveExibirAtributoInicio = (atributo, newValue, id, produtoData) => {
      if (atributo.ncm_atributo_objetivos.length === 0) return false;

      if (!atributo.ncm_atributo_condicoes || atributo.ncm_atributo_condicoes.length === 0) {
        return true;
      }

      const temCondicaoComId = atributo.ncm_atributo_condicoes.some((condicao) => condicao.ncm_atributo_id === id);
      if ((atributo.id !== id && !temCondicaoComId) || atributo.ignorar) return atributo.exibir;

      return atributo.ncm_atributo_condicoes.every((condicao) => {
        const atributoCondicionado = produtoData?.produto_atributos.find(
          (item) =>
            item.ncm_atributo_id === condicao.ncm_atributo_id &&
            ((condicao.ncm_atributo_dominio && item.ncm_atributo_dominio == 'true') ||
              (condicao.ncm_atributo_dominio_id != undefined &&
                condicao.ncm_atributo_dominio_id == item.ncm_atributo_dominio_id)),
        );

        if (!atributoCondicionado) {
          return false;
        }

        return true;
      });
    };

    const atualizarAtributos = () => {
      const atributosData =
        tipo === 'IMPORTACAO' ? produtoData.ncm_atributos?.importacao : produtoData.ncm_atributos?.exportacao;

      if (atributosData) {
        const novosAtributos = atributosData.map((atributo) => {
          if (atributo.ncm_atributo_subatributos && atributo.ncm_atributo_subatributos.length > 0) {
            const subAtributos = atributo.ncm_atributo_subatributos.map((subatributo) => {
              const subAtributoEncontrado = produtoData.produto_atributos.find(
                (item) => item.ncm_atributo_id === subatributo.id,
              );

              return {
                ...subatributo,
                selectedOption: subAtributoEncontrado
                  ? {
                      value:
                        subAtributoEncontrado.ncm_atributo_dominio_id || subAtributoEncontrado.ncm_atributo_dominio,
                      label:
                        subAtributoEncontrado.ncm_atributo_dominio_nome || subAtributoEncontrado.ncm_atributo_dominio,
                    }
                  : null,
                ignorar: subatributo.ncm_atributo_dominio === 'true' || subatributo.ncm_atributo_dominio === 'false',
                exibir: deveExibirAtributoInicio(
                  subatributo,
                  subAtributoEncontrado?.ncm_atributo_dominio_id ?? subAtributoEncontrado?.ncm_atributo_dominio ?? null,
                  subatributo.id,
                  produtoData,
                ),
              };
            });

            return {
              ...atributo,
              subAtributos: subAtributos,
              selectedOption: null,
              ignorar: atributo.ncm_atributo_dominio === 'true' || atributo.ncm_atributo_dominio === 'false',
              exibir: deveExibirAtributoInicio(atributo, null, atributo.id, produtoData),
            };
          }

          const atributoEncontrado = produtoData.produto_atributos.find((item) => item.ncm_atributo_id === atributo.id);

          return {
            ...atributo,
            selectedOption: atributoEncontrado
              ? {
                  value: atributoEncontrado.ncm_atributo_dominio_id || atributoEncontrado.ncm_atributo_dominio,
                  label: atributoEncontrado.ncm_atributo_dominio_nome || atributoEncontrado.ncm_atributo_dominio,
                }
              : null,
            ignorar: atributo.ncm_atributo_dominio === 'true' || atributo.ncm_atributo_dominio === 'false',
            exibir: deveExibirAtributoInicio(
              atributo,
              atributoEncontrado?.ncm_atributo_dominio_id ?? atributoEncontrado?.ncm_atributo_dominio ?? null,
              atributo.id,
              produtoData,
            ),
          };
        });

        setAtributos(novosAtributos);
      }
    };

    atualizarAtributos();
  }, [produtoData, tipo]);

  const deveExibirAtributo = (atributo, newValue, id, atributosNovos) => {
    if (atributo.ncm_atributo_objetivos.length === 0) return false;

    if (!atributo.ncm_atributo_condicoes || atributo.ncm_atributo_condicoes.length === 0) {
      return true;
    }

    const temCondicaoComId = atributo.ncm_atributo_condicoes.some((condicao) => condicao.ncm_atributo_id === id);
    if ((atributo.id !== id && !temCondicaoComId) || atributo.ignorar) return atributo.exibir;

    return atributo.ncm_atributo_condicoes.every((condicao) => {
      const atributoCondicionado = atributosNovos.find((item) => item.id === condicao.ncm_atributo_condicionado_id);

      if (!atributoCondicionado) {
        return false;
      }

      const atributoBase = atributosNovos.some(
        (atributoBase) =>
          (atributoBase.id === condicao.ncm_atributo_id &&
            atributo.id === condicao.ncm_atributo_condicionado_id &&
            condicao.ncm_atributo_dominio_id === atributoBase.selectedOption?.value) ||
          condicao.ncm_atributo_dominio === atributoBase.selectedOption?.value ||
          (atributoBase.selectedOption?.value === 'true' && condicao.ncm_atributo_dominio),
      );

      if (atributoBase) return true;

      if (condicao.operador === '==') {
        return (
          condicao.ncm_atributo_dominio_id === newValue?.value ||
          condicao.ncm_atributo_dominio === newValue?.value ||
          (newValue?.value === 'true' && condicao.ncm_atributo_dominio)
        );
      } else if (condicao.operador === '!=') {
        return (
          condicao.ncm_atributo_dominio_id === newValue?.value || condicao.ncm_atributo_dominio !== newValue?.value
        );
      }

      return true;
    });
  };

  return (
    <div className='col-sm-12'>
      <H4>{tipo === 'IMPORTACAO' ? 'Importação' : 'Exportação'}</H4>
      <div className='row mb-2'>
        {atributos.length > 0 ? (
          atributos.map((atributo) => {
            const dominios = atributo.ncm_atributo_dominios.map((dominio) => ({
              value: dominio.id,
              label: `${dominio.codigo} - ${dominio.descricao}`,
            }));
            return (
              <Atributo
                key={atributo.id}
                atributo={atributo}
                dominios={dominios}
                produtoData={produtoData}
                updateDadosEditados={updateDadosEditados}
                atributos={atributos}
                setAtributos={setAtributos}
                deveExibirAtributo={deveExibirAtributo}
                isEditMode={isEditMode}
              />
            );
          })
        ) : (
          <Span>Nenhum atributo encontrado.</Span>
        )}
      </div>
    </div>
  );
};

export default AtributosNCM;
