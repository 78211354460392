import ConfigDB from '../../../Themes/Config/ThemeConfig';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;

export const RecentChart = {
  series: [
    {
      type: 'line',
      name: 'VMCV Total (US$)',
      data: [],
    },
    {
      type: 'area',
      name: 'Nº de Processos',
      data: [],
    },
  ],
  options: {
    chart: {
      height: 355,
      type: 'area',
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        left: 8,
        blur: 0,
        color: secondary,
        opacity: 0.1,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
      width: [5, 0],
      colors: [secondary, primary],
    },

    grid: {
      borderColor: '#3f3a591a',
    },

    fill: {
      type: 'solid',
      opacity: [1, 0.2],
    },
    tooltip: {
      custom: function ({ series, dataPointIndex, w }) {
        let html = "<div className='arrow_box'>";

        Object.keys(series).forEach((key) => {
          if (series[key][dataPointIndex] != null) {
            html += `<span>
                ${w.globals.seriesNames[key]}: ${series[key][dataPointIndex]}
              </span><br>`;
          }
        });

        html += '</div>';

        return html;
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',

        grid: {
          drawOnChartArea: false,
        },
      },
    },
    yaxis: [
      {
        seriesName: 'VMCV Total (US$)',
        type: 'linear',
        display: true,
        position: 'left',
      },
      {
        seriesName: 'Nº de Processos',
        opposite: true,
        position: 'right',
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    ],
    xaxis: {
      type: 'month',
      categories: [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: true,
        width: 50,
        position: 'back',
        opacity: 0.2,
        stroke: {
          color: secondary,
          width: 0,
          dashArray: 0,
        },

        fill: {
          type: 'solid',
          color: secondary,
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 357,
          },
        },
      },
      {
        breakpoint: 575,
        options: {
          chart: {
            height: 280,
          },
        },
      },

      {
        breakpoint: 400,
        options: {
          chart: {
            height: 230,
          },
        },
      },
    ],
    colors: [secondary, primary],
  },
};

export const SalesChart = {
  series: [
    {
      name: 'Statistics',
      data: [20, 60, 50, 70, 40, 80, 20],
    },
    {
      name: 'Statistics',
      data: [80, 40, 50, 30, 60, 20, 20],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 100,
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
        borderRadius: 2,
        borderRadiusApplication: 'end',
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: [primary, '#dad8e0'],
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'bottomRight',
        offsetY: 0,
        offsetX: 0,
      },
    },
    responsive: [
      {
        breakpoint: 1661,
        options: {
          chart: {
            width: 80,
          },
        },
      },
      {
        breakpoint: 1365,
        options: {
          chart: {
            width: 80,
          },
        },
      },
    ],
  },
};

export const IncomeChart = {
  series: [
    {
      name: 'Statistics',
      data: [20, 60, 50, 70, 40, 80, 20],
    },
    {
      name: 'Statistics',
      data: [80, 40, 50, 30, 60, 20, 20],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 100,
      width: '100%',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
        borderRadius: 2,
        borderRadiusApplication: 'end',
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: [secondary, '#faded1'],
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'bottomRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
  },
};

export const OrderChart = {
  series: [
    {
      name: 'Statistics',
      data: [20, 60, 50, 70, 40, 80, 20],
    },
    {
      name: 'Statistics',
      data: [80, 40, 50, 30, 60, 20, 20],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 100,
      width: '100%',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
        borderRadius: 2,
        borderRadiusApplication: 'end',
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: [primary, '#dad8e0'],
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'bottomRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
  },
};

export const VisitorChart = {
  series: [
    {
      name: 'Statistics',
      data: [20, 60, 50, 70, 40, 80, 20],
    },
    {
      name: 'Statistics',
      data: [80, 40, 50, 30, 60, 20, 20],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 100,
      width: '100%',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
        borderRadius: 2,
        borderRadiusApplication: 'end',
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: [secondary, '#faded1'],
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'bottomRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
  },
};
