import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatNumber } from '../../../../../utils/formatacoes';

const ValoresTable = ({ processoData }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'descricao',
      name: '',
      selector: (row) => row.descricao,
    },
    {
      id: 'moeda',
      name: 'Moeda',
      selector: (row) => row.moeda ?? '-',
    },
    {
      id: 'valorMoeda',
      name: 'Valor Moeda',
      right: true,
      selector: (row) => formatNumber(row.valorMoeda) ?? '-',
    },
    {
      id: 'taxaMoeda',
      name: 'Taxa Moeda',
      right: true,
      selector: (row) => formatNumber(row.taxaMoeda, 4) ?? '-',
    },
    {
      id: 'valorDolar',
      name: 'Valor US$',
      right: true,
      selector: (row) => formatNumber(row.valorDolar) ?? '-',
    },
    {
      id: 'valorReal',
      name: 'Valor R$',
      right: true,
      selector: (row) => formatNumber(row.valorReal) ?? '-',
    },
  ];

  useEffect(() => {
    if (!processoData) return;

    const processoMoeda = processoData.taxas[processoData.moeda_id] ?? null;
    const freteMoeda = processoData.taxas[processoData.frete_moeda_id] ?? null;
    const seguroMoeda = processoData.taxas[processoData.seguro_moeda_id] ?? null;

    const linhas = [
      {
        descricao: 'Frete',
        moeda: freteMoeda?.simbolo ?? '',
        valorMoeda: processoData.frete_total_moeda,
        taxaMoeda: freteMoeda?.valor ?? '',
        valorDolar: processoData.frete_total_dolar,
        valorReal: processoData.frete_total_reais,
      },
      {
        descricao: 'Seguro',
        moeda: seguroMoeda?.simbolo ?? '',
        valorMoeda: processoData.seguro_total_moeda,
        taxaMoeda: seguroMoeda?.valor ?? '',
        valorDolar: processoData.seguro_total_dolar,
        valorReal: processoData.seguro_total_reais,
      },
      {
        descricao: 'VMLE',
        moeda: processoMoeda?.simbolo ?? '',
        valorMoeda: processoData.total_vmle_moeda,
        taxaMoeda: processoMoeda?.valor ?? '',
        valorDolar: processoData.total_vmle_dolar,
        valorReal: processoData.total_vmle_real,
      },
    ];

    setRows(linhas);
  }, [processoData]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} />
    </Fragment>
  );
};

export default ValoresTable;
