import { api } from '../api';

const ExportacaoProcessosService = {
  index: async (
    eventoCondicoesId = null,
    page = 1,
    limit = 20,
    sortColumn = null,
    sortOrder = null,
    filtros = {},
    favoritos = false,
  ) => {
    try {
      let url = `exportacao/processos/index?page=${page}&limit=${limit}`;

      if (eventoCondicoesId) {
        url += `&eventoCondicoesId=${eventoCondicoesId}`;
      }

      if (sortColumn && sortOrder) {
        url += `&sortColumn=${sortColumn}&sortOrder=${sortOrder}`;
      }

      if (filtros) {
        Object.keys(filtros).forEach((campo) => {
          url += `&${campo}=${filtros[campo]}`;
        });
      }

      if (favoritos) {
        url += `&favoritos=true`;
      }

      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Falha ao obter os dados da tabela');
    }
  },

  view: async (id) => {
    try {
      const response = await api.get(`exportacao/processos/view/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Falha ao obter os dados da tabela');
    }
  },

  downloadDoc: async (fileId) => {
    try {
      const response = await api.get(`exportacao/processos/downloadDoc/${fileId}`);
      return response.data;
    } catch (error) {
      console.error('Detalhes do erro:', error.response);
      throw new Error('Falha ao fazer o download');
    }
  },

  uploadDoc: async (processId, formData) => {
    try {
      const response = await api.post(`exportacao/processos/upload/${processId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      console.error('Upload error response:', error.response);
      throw new Error('Falha ao fazer o upload');
    }
  },
  getRelatorios: async () => {
    try {
      const response = await api.get(`exportacao/processos/getRelatorios`);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao obter os relatórios.');
    }
  },
  relatorio: async (id, page = 1, limit = 20, sortColumn = null, sortOrder = null, filtros = {}) => {
    try {
      let url = `exportacao/processos/relatorio/${id}?page=${page}&limit=${limit}`;

      if (sortColumn && sortOrder) {
        url += `&sortColumn=${sortColumn}&sortOrder=${sortOrder}`;
      }

      if (filtros) {
        Object.keys(filtros).forEach((campo) => {
          url += `&${campo}=${filtros[campo]}`;
        });
      }

      const response = await api.get(url, {
        timeout: 270000,
      });

      return response.data;
    } catch (error) {
      throw new Error('Erro ao obter o relatório.');
    }
  },
  filtrosRelatorio: async (id) => {
    try {
      const response = await api.get(`exportacao/processos/filtrosRelatorio/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao obter o relatório.');
    }
  },
  relatorioPlanilha: async (relatorioId, filtros) => {
    try {
      let url = `exportacao/processos/relatorioPlanilha/?relatorioId=${relatorioId}`;

      if (filtros) {
        Object.keys(filtros).forEach((campo) => {
          url += `&${campo}=${filtros[campo]}`;
        });
      }

      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao obter o relatório.');
    }
  },
  relNumerario: async (id) => {
    try {
      const response = await api.get(`exportacao/processos/relNumerario/${id}`, {
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      throw new Error('Erro ao gerar o relatório.');
    }
  },
};

export default ExportacaoProcessosService;
