import { formatDate } from '../../../utils/formatacoes';
import P from '../Paragraph';
import Span from '../Span';

const EventsTimeline = ({ eventos }) => {
  const getIcon = (situacao) => {
    switch (situacao) {
      case 1: //realizado
        return (
          <Span>
            <i
              className='icofont icofont-check-circled txt-success'
              style={{
                fontSize: '24px',
              }}
              title='Realizado'
            ></i>
          </Span>
        );
      case 2: //realizado com atraso
        return (
          <Span>
            <i
              className='icofont icofont-check-circled txt-danger'
              style={{
                fontSize: '24px',
              }}
              title='Realizado com Atraso'
            ></i>
          </Span>
        );
      default: //pendente
        return (
          <Span>
            <i
              className='icofont icofont-check-circled txt-warning'
              style={{
                fontSize: '24px',
              }}
              title='Pendente'
            ></i>
          </Span>
        );
    }
  };

  const getObservacao = (observacao) => {
    observacao = observacao ?? '';
    let texto = observacao;

    if (texto.length > 50) {
      texto = observacao.substr(0, 50) + '...';
    }

    return <P title={observacao}>{texto}</P>;
  };

  const getBadge = (situacao, dataRealizada) => {
    let cor = 'badge-warning';

    switch (situacao) {
      case 1: //realizado
        cor = 'badge-success';
        break;
      case 2: //realizado com atraso
        cor = 'badge-danger';
        break;
    }

    return <span className={`badge ${cor}`}>{formatDate(dataRealizada) ?? 'Pendente'}</span>;
  };

  return (
    <div className='table-responsive'>
      <table
        className='table table-bordernone'
        style={{
          border: 0,
        }}
      >
        <tbody>
          {eventos &&
            eventos.map((evento, index) => (
              <tr key={index}>
                <td
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {getIcon(evento.situacao)}
                </td>
                <td
                  style={{
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  <h5>{evento.nome}</h5>
                  {getObservacao(evento.observacao)}
                </td>
                <td
                  className='text-end'
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {getBadge(evento.situacao, evento.data_realizada)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventsTimeline;
