import axios from 'axios';
import { API_URL } from '../Themes/Config/appConfig';
import authService from './Auth/authService';
import { toast } from 'react-toastify';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const requestInterceptor = (req) => {
  const token = authService.getToken();
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
};

const responseErrorInterceptor = (err) => {
  const url = err.config.url;
  const isLogin = /login/.test(url);

  if (err?.response?.status === 401 && !isLogin) {
    authService.logout();
    toast.error('Sessão expirada. Entre novamente.');
    window.location.reload();
  } else {
    return Promise.reject(err);
  }
};

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use((res) => res, responseErrorInterceptor);
