import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { formatValue } from '../../../utils/formatacoes';
import { Card, CardBody, Col } from 'reactstrap';
import HeaderCard from './HeaderCard';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';

const FavoritosChart = ({ data, plugin }) => {
  const index = `/${plugin}/processos`;
  const view = `/${plugin}/processos/view/`;

  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
  });

  const navigate = useNavigate();

  const handleIndexFavoritosClick = () =>
    navigate(index, {
      state: {
        favoritos: true,
      },
    });

  useEffect(() => {
    if (!data) return;

    const handleViewClick = (id) => navigate(`${view}${id}`);

    const colunas = [
      {
        name: '',
        id: 'actions',
        center: true,
        compact: true,
        width: '3vw',
        cell: (row) => (
          <i
            className='icofont icofont-eye-alt'
            onClick={() => handleViewClick(row['Processos.id'])}
            style={{ fontSize: 20, cursor: 'pointer' }}
            title='Visualizar processo'
          />
        ),
      },
    ];

    data.columns.forEach((coluna) => {
      const { label, campo, tipo, casasDecimais } = coluna;

      colunas.push({
        name: label,
        id: campo,
        sortable: true,
        right: tipo === 'decimal',
        wrap: true,
        selector: (row) => {
          const coluna = `Processos.${label}`;
          return row[coluna] ? formatValue(row[coluna], tipo, casasDecimais) : '-';
        },
      });
    });

    setTableData({
      columns: colunas,
      rows: data.rows,
    });
  }, [data, navigate, view]);

  return (
    <Fragment>
      <Col lg='12' sm='12'>
        <Card className='top-products'>
          <HeaderCard
            title='Processos Favoritos'
            span1='Mostrando os cinco últimos processos modificados.'
            setting={true}
          />
          <CardBody>
            <div className='row mb-2'>
              <div className='col-sm-12'>
                <DataTable
                  noDataComponent='Nenhum registro encontrado.'
                  data={tableData.rows}
                  columns={tableData.columns}
                  striped={true}
                  responsive={true}
                  highlightOnHover={true}
                />
              </div>
            </div>

            <div className='row'>
              <div
                className='col-sm-12'
                style={{
                  paddingLeft: 25,
                  paddingRight: 25,
                }}
              >
                <Button
                  onClick={handleIndexFavoritosClick}
                  style={{
                    width: '100%',
                  }}
                >
                  Visualizar Todos
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FavoritosChart;
