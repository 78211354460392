import React, { Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { H3, P } from '../../../Themes/Components/AbstractElements';
import ConfigDB from '../../../Themes/Config/ThemeConfig';
import { Link } from 'react-router-dom';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

const EtapasChart = ({ data, index, setEventoCondicoesId }) => {
  const { total, icone, eventoId } = data.data;

  return (
    <Fragment>
      <Card className='sale-chart'>
        <CardBody>
          <div className='d-flex'>
            <div className='flex-shrink-0'>
              <div className='sale-detail'>
                <div className='icon'>
                  <i
                    className={`icofont icofont-${icone}`}
                    style={{
                      fontSize: '25px',
                      color: primary,
                    }}
                  ></i>
                </div>
                <div className='sale-content'>
                  {setEventoCondicoesId ? (
                    <>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setEventoCondicoesId(eventoId);
                        }}
                      >
                        <H3>{data.titulo}</H3>
                      </Link>
                      <P>{total}</P>
                    </>
                  ) : (
                    <>
                      <Link
                        to={index}
                        state={{
                          eventoCondicoesId: eventoId,
                        }}
                      >
                        <H3>{data.titulo}</H3>
                      </Link>
                      <P>{total}</P>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default EtapasChart;
