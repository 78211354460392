import { Fragment } from 'react';
import Span from '../Span';

const Loading = () => {
  return (
    <Fragment>
      <div
        className='loader-box'
        style={{
          height: '100px',
          flexDirection: 'column',
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div className='mb-2'>
          <Span>Carregando...</Span>
        </div>
        <div className='loader-19'></div>
      </div>
    </Fragment>
  );
};

export default Loading;
