import React, { Fragment, useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn } from '../../Themes/Components/AbstractElements';
import { useNavigate } from 'react-router-dom';
import FormHeader from './FormHeader';
import { toast } from 'react-toastify';
import authService from '../../Services/Auth/authService';

const LoginTab = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();

  const loginAuth = async (e) => {
    e.preventDefault();

    try {
      const response = await authService.login(email, password);
      if (response.token) {
        navigate(response.pessoa.configuracoes.dashboard_inicial);
      } else {
        toast.error('Falha no login. Por favor, verifique seu e-mail e senha.');
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Erro desconhecido. Por favor, tente novamente mais tarde.');
        }
      } else {
        toast.error('Falha na comunicação com o servidor. Por favor, tente novamente mais tarde.');
      }
    }
  };

  return (
    <Fragment>
      <Form className='theme-form'>
        <FormHeader />
        <FormGroup className='form-group'>
          <Label className='col-form-label'>Endereço de email</Label>
          <Input className='form-control' type='email' onChange={(e) => setEmail(e.target.value)} defaultValue={''} />
        </FormGroup>
        <FormGroup className='form-group'>
          <Label className='col-form-label'>Senha</Label>
          <div className='form-input position-relative'>
            <Input
              className='form-control'
              type={togglePassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              defaultValue={''}
              required
            />
            <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
              <span className={togglePassword ? '' : 'show'}></span>
            </div>
          </div>
        </FormGroup>
        <div className='text-end mt-3'>
          <Btn
            attrBtn={{
              color: 'primary w-100',
              className: 'btn-block',
              onClick: (e) => loginAuth(e),
            }}
          >
            Entrar
          </Btn>
        </div>
      </Form>
    </Fragment>
  );
};

export default LoginTab;
