import { useEffect, useState } from 'react';
import ExportacaoProcessosService from '../../../../Services/Exportacao/ExportacaoProcessosService';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../../../Themes/Components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router';
import PageLoader from '../../../../Themes/Layout/PageLoader';
import { H3, H4, Span } from '../../../../Themes/Components/AbstractElements';
import { formatDate, formatNumber } from '../../../../utils/formatacoes';
import ValoresTable from './components/valoresTable';
import DocumentosTable from './components/documentosTable';
import UploadButton from '../../../../Themes/Components/UploadButton/uploadButton';
import DespesasTable from './components/despesasTable';
import NumerariosTable from './components/numerariosTable';
import EventsTimeline from '../../../../Themes/Components/EventsTimeline';
import ResumoFinanceiroTable from './components/resumoFinanceiroTable';
import DuesTable from './components/duesTable';
import NfsTable from './components/nfsTable';
import BreadcrumbProcessos from '../../../../Themes/Components/BreadcrumbProcessos';
import { TIPO_DOC_EXPO_OBRIGATORIO } from '../../../../Themes/Config/appConfig';

const ExportacaoProcessosView = () => {
  const [processoData, setProcessoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const docRequired = localStorage.getItem(TIPO_DOC_EXPO_OBRIGATORIO);

  useEffect(() => {
    const view = async () => {
      try {
        const data = await ExportacaoProcessosService.view(id);

        setProcessoData(data);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        navigate('/exportacao/processos');
      }
    };

    view();
  }, [id]);

  const handleUploadFile = async (id, files) => {
    try {
      const fileWithoutType = files.find((file) => !file.tipoDocumento || !file.tipoDocumento.id);

      if (docRequired && fileWithoutType) {
        return toast.error('O tipo de documento é obrigatório para todos os arquivos!');
      }

      toast.info('Enviando arquivos...');
      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file.file, file.file.name, file.file.identificacao);
        formData.append(
          `tipoDocumentos[${index}]`,
          file.tipoDocumento && file.tipoDocumento.id ? file.tipoDocumento.id : null,
        );
      });

      const response = await ExportacaoProcessosService.uploadDoc(id, formData);

      toast.success(response.message);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <PageLoader loaded={loading} />
      <BreadcrumbProcessos
        mainTitle={`Processo ${processoData?.ref_cliente ?? ''}`}
        title='Processos'
        parent='Exportação'
        model={'Exportacao.Processos'}
        processoId={id}
        favorito={processoData?.favorito ?? false}
      />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-9'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Dados Gerais</H3>

                    <div className='row mb-2'>
                      <div className='col-sm-2'>
                        <H4>Número</H4>
                        <Span>{processoData?.numero}</Span>
                      </div>

                      <div className='col-sm-8'>
                        <H4>Ref. Cliente</H4>
                        <Span>{processoData?.ref_cliente}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Data Abertura</H4>
                        <Span>{formatDate(processoData?.data_abertura, false)}</Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-6'>
                        <H4>Cliente</H4>
                        <Span>
                          {processoData?.pessoa
                            ? processoData.pessoa.cpfcnpj + ' - ' + processoData.pessoa.fantasia
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-6'>
                        <H4>Exportador</H4>
                        <Span>
                          {processoData?.exportador
                            ? processoData.exportador.cpfcnpj + ' - ' + processoData.exportador.fantasia
                            : '-'}
                        </Span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Valores Totais</H3>

                    <div className='row'>
                      <div className='col-xl-3'>
                        <H4>Moeda</H4>
                        <Span>
                          {processoData?.moeda
                            ? processoData.moeda.simbolo + ' - ' + processoData.moeda.descricao
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-xl-3'>
                        <H4>Incoterm</H4>
                        <Span>
                          {processoData?.incoterm
                            ? processoData.incoterm.codigo + ' - ' + processoData.incoterm.nome
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-xl-3'>
                        <H4>Tipo de Frete</H4>
                        <Span>{processoData?.tipo_frete ?? '-'}</Span>
                      </div>

                      <div className='col-xl-3'>
                        <H4>Comissão do Agente</H4>
                        <Span>
                          {formatNumber(processoData?.comissao_valor) ?? '-'} (
                          {formatNumber(processoData?.comissao_agente) ?? '-'}%)
                        </Span>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <ValoresTable processoData={processoData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Documentos</H3>

                    <div className='row mb-2'>
                      <div className='col-xl-12 d-flex justify-content-end'>
                        <UploadButton id={id} options={processoData?.selectDocumentos} callback={handleUploadFile} />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <DocumentosTable documentos={processoData?.processo_documentos} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Notas de Exportação vinculadas à DU-Es</H3>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <DuesTable dues={processoData?.dues} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Notas de Exportação não vinculadas à DU-Es</H3>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <NfsTable nfs={processoData?.processo_notas} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Carga</H3>

                    <div className='row mb-2'>
                      <div className='col-sm-4'>
                        <H4>Unidade de Despacho</H4>
                        <Span>
                          {processoData?.alfandegados_despacho
                            ? processoData.alfandegados_despacho.codigo +
                              ' - ' +
                              processoData.alfandegados_despacho.descricao
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-8'>
                        <H4>Recinto de Despacho</H4>
                        <Span>
                          {processoData?.aduaneiros_despacho
                            ? processoData.aduaneiros_despacho.codigo +
                              ' - ' +
                              processoData.aduaneiros_despacho.descricao
                            : '-'}
                        </Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-4'>
                        <H4>Unidade de Despacho</H4>
                        <Span>
                          {processoData?.alfandegados_embarque
                            ? processoData.alfandegados_embarque.codigo +
                              ' - ' +
                              processoData.alfandegados_embarque.descricao
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-8'>
                        <H4>Recinto de Despacho</H4>
                        <Span>
                          {processoData?.aduaneiros_embarque
                            ? processoData.aduaneiros_embarque.codigo +
                              ' - ' +
                              processoData.aduaneiros_embarque.descricao
                            : '-'}
                        </Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-3'>
                        <H4>Previsão de Embarque (ETD)</H4>
                        <Span>{formatDate(processoData?.previsao_embarque, false) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-3'>
                        <H4>Previsão de Chegada (ETA)</H4>
                        <Span>{formatDate(processoData?.previsao_chegada, false) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Peso Bruto</H4>
                        <Span>{formatNumber(processoData?.peso_bruto, 5) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Peso Liquido</H4>
                        <Span>{formatNumber(processoData?.total_peso_liquido, 5) ?? '-'}</Span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Financeiro</H3>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <H4>Resumo Financeiro</H4>
                        <ResumoFinanceiroTable totaisLancamentos={processoData?.totais_lancamentos} />
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <H4>Despesas Pagas pelo Cliente</H4>
                        <DespesasTable despesas={processoData?.processo_despesas} responsabilidade={2} />
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <H4>Despesas Pagas pela Comissária</H4>
                        <DespesasTable despesas={processoData?.processo_despesas} responsabilidade={1} />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <H4>Numerários</H4>
                        <NumerariosTable numerarios={processoData?.processo_numerarios} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-3'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Eventos</H3>
                    <EventsTimeline eventos={processoData?.processo_eventos} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportacaoProcessosView;
