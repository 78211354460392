import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const EmbalagensTable = ({ embalagens }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'descricao',
      name: 'Embalagem',
      selector: (row) => (row.tipo_embalagem ? row.tipo_embalagem.codigo + ' - ' + row.tipo_embalagem.descricao : '-'),
    },
    {
      id: 'quantidade',
      name: 'Quantidade',
      selector: (row) => row.quantidade,
    },
  ];

  useEffect(() => {
    if (!embalagens) return;

    setRows(embalagens);
  }, [embalagens]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default EmbalagensTable;
