import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate } from '../../../../../utils/formatacoes';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ConteinerEventosTable from './conteinerEventosTable';
import Span from '../../../../../Themes/Components/Span';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ConteineresTable = ({ conteineres }) => {
  const [rows, setRows] = useState([]);
  const [selectedConteiner, setSelectedConteiner] = useState(null);

  const columns = [
    {
      name: 'Número',
      selector: (row) => row.numero ?? '-',
    },
    {
      name: 'Data Descarregamento',
      selector: (row) => formatDate(row.data_descarregamento) ?? '-',
    },
    {
      name: 'Data Devolução',
      selector: (row) => formatDate(row.data_devolucao) ?? '-',
    },
    {
      name: 'Tempo no Porto',
      selector: (row) =>
        row.tempo_porto ? <span className='badge badge-primary'>{row.tempo_porto + ' dias'}</span> : '-',
    },
    {
      name: 'Último Evento',
      width: '20vw',
      selector: (row) => {
        const lastEvent = row.embarque_contentor_eventos?.[row.embarque_contentor_eventos.length - 1];
        return lastEvent ? `${lastEvent.descricao} (${formatDate(lastEvent.data)})` : '-';
      },
    },
    {
      name: 'Ações',
      width: '4vw',
      right: true,
      cell: (row) => (
        <span className='badge badge-primary' style={{ cursor: 'pointer' }} onClick={() => setSelectedConteiner(row)}>
          <i className='icofont icofont-history' style={{ fontSize: 16 }} title='Histórico' />
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (conteineres) {
      setRows(conteineres);
    }
  }, [conteineres]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
      <Modal show={!!selectedConteiner} onHide={() => setSelectedConteiner(null)} size='lg'>
        <ModalHeader>Histórico de Eventos - {selectedConteiner?.numero}</ModalHeader>
        <ModalBody>
          <ConteinerEventosTable events={selectedConteiner?.embarque_contentor_eventos} />
          <Span className='m-t-15'>
            Observação: eventos ocorridos antes da primeira consulta do embarque não aparecerão no histórico de eventos
            do contêiner.
          </Span>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => setSelectedConteiner(null)}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ConteineresTable;
