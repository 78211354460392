export const formatDate = (string, showTime = true) => {
  if (!string) return null;

  const date = new Date(string);

  const options = {
    timeZone: 'America/Sao_Paulo',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  if (string.length > 10 && showTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }

  return date.toLocaleDateString('pt-BR', options).replace(',', '');
};

export const formatNumber = (numero, casasDecimais = 2, sigla = false) => {
  if (numero === null) return null;

  const options = {
    minimumFractionDigits: casasDecimais,
    maximumFractionDigits: casasDecimais,
    style: 'decimal',
  };

  if (!sigla) {
    return new Intl.NumberFormat('pt-BR', options).format(numero);
  }

  return sigla + ' ' + new Intl.NumberFormat('pt-BR', options).format(numero);
};

export const formatValue = (valor, tipo, casasDecimais = null) => {
  switch (tipo) {
    case 'date':
    case 'datetime':
    case 'timestamp':
      return formatDate(valor);
    case 'decimal':
      return formatNumber(valor, casasDecimais);
    default:
      return valor;
  }
};

export const onlyNumbers = (data) => {
  return data.replace(/[^0-9]/g, '');
};
