import React, { Fragment } from 'react';
import { H2, P } from '../../Themes/Components/AbstractElements';

const FormHeader = () => {
  return (
    <Fragment>
      <H2 attrH2={{ className: 'text-center' }}>Acessar o Painel</H2>
      <P attrPara={{ className: 'text-center' }}>Digite seu e-mail e senha para entrar</P>
    </Fragment>
  );
};

export default FormHeader;
