import Routers from './Route';
import AnimationThemeProvider from './Themes/_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './Themes/_helper/Customizer/CustomizerProvider';
import FilterProvider from './Themes/_helper/Ecommerce/Filter/FilterProvider';
import CartProvider from './Themes/_helper/Ecommerce/Cart/CartProvider';
import ContactProvider from './Themes/_helper/Contact/ContactProvider';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <>
      <AnimationThemeProvider>
        <CustomizerProvider>
          <CartProvider>
            <FilterProvider>
              <ContactProvider>
                <Routers />
              </ContactProvider>
            </FilterProvider>
          </CartProvider>
        </CustomizerProvider>
      </AnimationThemeProvider>
      <ToastContainer />
    </>
  );
};
export default App;
