import React from 'react';
import './timeline.css';
import { formatDate } from '../../../../../utils/formatacoes';
import { H5, Span } from '../../../../../Themes/Components/AbstractElements';

const TimelineComponent = ({ embarque }) => {
  if (!embarque?.embarque_eventos || embarque?.embarque_eventos.length === 0) {
    return (
      <div className='text-center'>
        {embarque?.status_id === 0 || embarque?.status_id === 39 ? (
          <h5>O embarque ainda não foi consultado. Volte em breve, por favor!</h5>
        ) : embarque?.status_id === 19 ? (
          <h5>Parece que não foi possível localizar o embarque?. Não há eventos para exibir...</h5>
        ) : (
          <h5>Ainda não existem eventos para esse embarque?.</h5>
        )}
      </div>
    );
  }

  return (
    <div className='timeline-responsive'>
      <ul className='timeline' id='timeline'>
        {embarque?.embarque_eventos.map((evento, index) => {
          let classSituacao = 'pending';
          switch (evento.situacao) {
            case 0:
              classSituacao = 'complete';
              break;
            case 1:
              classSituacao = 'actual';
              break;
            default:
              classSituacao = 'pending';
              break;
          }

          let origem = evento.origem === 2 ? 'Aduaneiro' : 'Transporte Internacional';
          console.log(embarque?.embarque_eventos);
          return (
            <li key={index} className={`li ${classSituacao}`}>
              <div className='timestamp'>
                <span
                  className='badge badge-primary'
                  style={{
                    display: 'inline-block',
                    lineHeight: '14px',
                  }}
                >
                  {origem}
                </span>

                <span
                  className='badge badge-light'
                  style={{
                    display: 'inline-block',
                    lineHeight: '14px',
                    marginTop: 5,
                    color: '#000',
                  }}
                >
                  {formatDate(evento?.data) ?? '-'}
                  {evento.timezone_desconhecido && (
                    <i
                      className='fa fa-exclamation-triangle'
                      aria-hidden='true'
                      title='Timezone não reportado. O horário pode não estar no horário de Brasília.'
                    ></i>
                  )}
                </span>
              </div>
              <div className='status'>
                <div className='text-center' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <H5>{evento.descricao}</H5>
                  {evento.embarque_navio && (
                    <span
                      title={`Código IMO: ${evento.embarque_navio.navio.codigo_imo}`}
                      className={`badge badge-${evento.situacao == 0 ? 'success' : evento.situacao == 1 ? 'warning' : 'light'} `}
                      data-toggle='tooltip'
                      style={{
                        display: 'inline-block',
                        lineHeight: '14px',
                        color: evento.situacao == 0 ? '#fff' : '#000',
                        marginTop: 5,
                      }}
                    >
                      <i className='icofont icofont-ship'></i>{' '}
                      {evento.embarque_navio.navio.nome + (evento.viagem ? ' - ' + evento.viagem : '')}
                    </span>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TimelineComponent;
