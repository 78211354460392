import { Fragment, useState } from 'react';
import { Input, Label } from 'reactstrap';
import Select from 'react-select';
import InputCpfcnpj from '../InputCpfcnpj';
import { Button, FormCheck, FormControl } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import ReactInputMask from 'react-input-mask';

const TableActions = ({
  dataFilters,
  handleFilterSubmit,
  actionButtons,
  setOnlyFavoritos,
  onlyFavoritos,
  showFiltroFavoritos,
  relatorio = false,
  clearTable = false,
  setValuesRelatorio = false,
}) => {
  const [values, setValues] = useState({});
  const [key, setKey] = useState(0);

  const handleBlur = (e, campo) => {
    setNewValues(e.target.value, campo);
  };

  const setNewValues = (value, campo) => {
    const newValues = {
      ...values,
    };

    // Verifica se o campo é de data e converte o valor para um objeto Date
    if (
      dataFilters[campo] &&
      (dataFilters[campo].tipo === 'datetime' ||
        dataFilters[campo].tipo === 'date' ||
        dataFilters[campo].tipo === 'timestamp')
    ) {
      newValues[campo] = value || null; // Define o valor como null se for vazio
    } else {
      newValues[campo] = value;
    }

    setValues(newValues);
    if (setValuesRelatorio) setValuesRelatorio(newValues);
  };

  const getElement = (elData) => {
    const { campo, tipo, options } = elData;

    const selOptions = Object.keys(options).map((opt) => {
      return {
        label: options[opt],
        value: opt,
      };
    });

    let el = '';

    switch (tipo) {
      case 'string':
        el = <Input name={campo} id={campo} autoComplete='off' onBlur={(e) => handleBlur(e, campo)} />;
        break;
      case 'cpfcnpj':
        el = <InputCpfcnpj id={campo} value={values[campo]} handleBlur={(e) => handleBlur(e, campo)} />;
        break;
      case 'datetime':
      case 'date':
      case 'timestamp':
        el = (
          <FormControl
            as={ReactDatePicker}
            autoComplete='off'
            id={campo}
            name={campo}
            selected={values[campo]}
            onChange={(e) => setNewValues(new Date(e), campo)}
            customInput={
              <ReactInputMask mask='99/99/9999'>
                {(inputProps) => <input {...inputProps} className='form-control datetimepicker-input digits' />}
              </ReactInputMask>
            }
            locale={ptBR}
            dateFormat='dd/MM/yyyy'
            className='form-control datetimepicker-input digits'
          />
        );
        break;
      case 'select':
        el = (
          <Select
            options={selOptions}
            placeholder='Selecione'
            onChange={(value) => {
              setNewValues(value.value, campo);
            }}
          />
        );
        break;
    }

    return el;
  };

  const clearFilters = async () => {
    setValues({});
    if (setValuesRelatorio) setValuesRelatorio({});
    clearTable();
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <Fragment key={key}>
      <div className='row mb-2'>
        {dataFilters &&
          Object.keys(dataFilters).map((key) => {
            return (
              <div
                key={key}
                className={`${
                  dataFilters[key].tipo == 'datetime' ||
                  dataFilters[key].tipo == 'date' ||
                  dataFilters[key].tipo == 'timestamp'
                    ? ' col-sm-1'
                    : ' col-sm-2'
                }`}
              >
                <Label htmlFor={dataFilters[key].campo}>{dataFilters[key].label}</Label>
                {getElement(dataFilters[key])}
              </div>
            );
          })}

        <div
          className='col-sm-1 mt-4'
          hidden={relatorio ? false : dataFilters ? Object.keys(dataFilters).length === 0 : true}
        >
          <Button onClick={() => handleFilterSubmit(values)} className='btn-primary'>
            Buscar
          </Button>
        </div>

        <div
          className='col-sm-1 mt-4'
          hidden={relatorio ? false : dataFilters ? Object.keys(dataFilters).length === 0 : true}
        >
          <Button onClick={() => clearFilters()} className='btn-light'>
            Limpar
          </Button>
        </div>

        {actionButtons &&
          Object.keys(actionButtons).map((key) => {
            return actionButtons[key];
          })}
      </div>
      <div className='row mb-2' hidden={!showFiltroFavoritos}>
        <div className='col-xs-12'>
          <FormCheck>
            <FormCheckInput
              id='setFavoritos'
              value={onlyFavoritos}
              checked={onlyFavoritos}
              onChange={(e) => setOnlyFavoritos(e.target.checked)}
            />
            <FormCheckLabel htmlFor='setFavoritos'>Mostrar apenas processos favoritos</FormCheckLabel>
          </FormCheck>
        </div>
      </div>
    </Fragment>
  );
};

export default TableActions;
