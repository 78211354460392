import { useEffect, useState } from 'react';
import Breadcrumbs from '../../../../Themes/Components/Breadcrumbs';
import ImportacaoProcessosService from '../../../../Services/Importacao/ImportacaoProcessosService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { formatValue } from '../../../../utils/formatacoes';
import { Button } from 'react-bootstrap';
import { downloadFile } from '../../../../utils/files';
import PageLoader from '../../../../Themes/Layout/PageLoader';
import TableActions from '../../../../Themes/Components/TableActions';
import { format } from 'date-fns';
import Loading from '../../../../Themes/Components/Loading';

const ImportacaoProcessosRelatorios = () => {
  const [relatorios, setRelatorios] = useState([]);
  const [relatorioId, setRelatorioId] = useState(null);
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
    total: 0,
    page: 1,
    limit: 20,
    sortColumn: null,
    sortOrder: null,
    filtros: {},
  });
  const [valuesRelatorio, setValuesRelatorio] = useState({});
  const [filtrosUsuario, setFiltrosUsuario] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const carregarRelatorios = async () => {
      setPageLoading(true);

      try {
        const data = await ImportacaoProcessosService.getRelatorios();

        if (data.length === 0) {
          toast.error('Não existem relatórios configurados.');
        } else {
          const options = Object.keys(data).map((id) => {
            return {
              value: id,
              label: data[id],
            };
          });

          setRelatorios(options);
          setPageLoading(false);
        }
      } catch (error) {
        setPageLoading(false);
        toast.error(error.message);
      }
    };

    carregarRelatorios();
  }, []);

  const relatorio = async (relatorioId, page, limit, sortColumn, sortOrder, filtros) => {
    setTableLoading(true);

    try {
      const data = await ImportacaoProcessosService.relatorio(relatorioId, page, limit, sortColumn, sortOrder, filtros);

      const colunas = [];

      data.columns.forEach((coluna) => {
        const { label, campo, tipo, casasDecimais } = coluna;

        colunas.push({
          name: label,
          id: campo,
          sortable: true,
          right: tipo === 'decimal',
          wrap: true,
          selector: (row) => {
            const coluna = `Processos.${label}`;
            return row[coluna] ? formatValue(row[coluna], tipo, casasDecimais) : '-';
          },
        });
      });

      setTableData({
        columns: colunas,
        rows: data.rows,
        total: data.pageInfo.total,
      });

      setTableLoading(false);
    } catch (error) {
      toast.error(error.message);
      clearTable();
      setTableLoading(false);
    }
  };

  const clearTable = async () => {
    setTableData({
      columns: [],
      rows: [],
      total: 0,
      page: 1,
      limit: 20,
      sortColumn: null,
      sortOrder: null,
      filtros: {},
    });
  };

  useEffect(() => {
    const filtrosRelatorio = async (relatorioId) => {
      setFilterLoading(true);
      try {
        const data = await ImportacaoProcessosService.filtrosRelatorio(relatorioId);

        setFiltrosUsuario(data.filtrosUsuario);
        setFilterLoading(false);
        clearTable();
      } catch (error) {
        toast.error(error.message);
        setFilterLoading(false);
      }
    };

    if (relatorioId) {
      filtrosRelatorio(relatorioId);
    }
  }, [relatorioId]);

  const handlePageChange = async (page, limit) => {
    try {
      setTableLoading(true);

      const data = await ImportacaoProcessosService.relatorio(
        relatorioId,
        page,
        limit,
        tableData.sortColumn,
        tableData.sortOrder,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        page,
        limit,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setTableLoading(false);
  };

  const handleSort = async (column, order) => {
    try {
      setTableLoading(true);

      const data = await ImportacaoProcessosService.relatorio(
        relatorioId,
        tableData.page,
        tableData.limit,
        column.id,
        order,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        sortColumn: column.id,
        sortOrder: order,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setTableLoading(false);
  };

  const handleExportar = async () => {
    setDownloading(true);
    toast.info('Por favor, aguarde. Gerando a planilha e fazendo o download...');

    try {
      const filtros = {};

      Object.keys(valuesRelatorio).forEach((campo) => {
        let value = valuesRelatorio[campo];

        if (value instanceof Date) {
          value = format(value, 'dd/MM/yyyy');
        }

        if (value.length > 0) {
          filtros[campo] = value;
        }
      });

      const data = await ImportacaoProcessosService.relatorioPlanilha(relatorioId, filtros);

      if (data.status === 200) {
        downloadFile(data);
        setDownloading(false);
        toast.success('Planilha exportada com sucesso.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmitFilters = async (values) => {
    if (!relatorioId) {
      toast.warning('Informe o modelo.');
      return;
    }

    const filtros = {};

    Object.keys(values).forEach((campo) => {
      let value = values[campo];

      if (value instanceof Date) {
        value = format(value, 'dd/MM/yyyy');
      }

      if (value.length > 0) {
        filtros[campo] = value;
      }
    });

    relatorio(relatorioId, tableData.page, tableData.limit, tableData.sortColumn, tableData.sortOrder, filtros);
  };

  return (
    <>
      <PageLoader loaded={pageLoading} />
      <Breadcrumbs mainTitle='Relatórios' title='Processos' parent='Importação' />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card'>
              <div className='card-body pt-2'>
                <Label className='col-form-label'>Relatório</Label>
                <Select
                  options={relatorios}
                  autoFocus
                  placeholder='Selecione'
                  onChange={(opt) => setRelatorioId(opt.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <div className='card'>
              <div className='card-header pb-0'>
                {filterLoading ? (
                  <Loading />
                ) : (
                  <TableActions
                    dataFilters={filtrosUsuario}
                    handleFilterSubmit={handleSubmitFilters}
                    relatorio={relatorioId ? true : false}
                    clearTable={clearTable}
                    setValuesRelatorio={setValuesRelatorio}
                    actionButtons={[
                      <div
                        key={0}
                        className='col-sm-2'
                        style={{
                          marginLeft: '1vh',
                        }}
                      >
                        <Button
                          className='btn-info mt-4'
                          hidden={!relatorioId}
                          disabled={downloading}
                          onClick={handleExportar}
                        >
                          {!downloading ? 'Exportar Planilha' : 'Baixando...'}
                        </Button>
                      </div>,
                    ]}
                  />
                )}
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <DataTable
                      noDataComponent={relatorioId ? 'Nenhum registro encontrado.' : 'Selecione um relatório.'}
                      data={tableData.rows}
                      columns={tableData.columns}
                      striped={true}
                      responsive={true}
                      highlightOnHover={true}
                      progressPending={tableLoading}
                      progressComponent={<Loading />}
                      pagination
                      paginationServer={true}
                      paginationPerPage={tableData.it}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                        rangeSeparatorText: 'de',
                      }}
                      onChangePage={(page) => handlePageChange(page, tableData.limit)}
                      onChangeRowsPerPage={(limit, page) => handlePageChange(page, limit)}
                      paginationTotalRows={tableData.total}
                      sortServer
                      onSort={handleSort}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportacaoProcessosRelatorios;
