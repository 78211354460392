import React, { Fragment, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../Themes/Layout/Loader';
import LayoutRoutes from './LayoutRoutes';
import PrivateRoute from './PrivateRoute';
import SignIn from '../Modules/login/Signin';
import authService from '../Services/Auth/authService';

const Routers = () => {
  const token = authService.getToken();

  return (
    <Fragment>
      <BrowserRouter basename='/'>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path='/' element={<PrivateRoute />}>
              {token ? <Route exact path='/' element={<Navigate to='/dashboards/importacao' />} /> : null}
              <Route path='/*' element={<LayoutRoutes />} />
            </Route>

            <Route exact path='/login' element={<SignIn />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routers;
