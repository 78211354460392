import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate } from '../../../../../utils/formatacoes';

const canal = {
  'SEM CANAL': 'silver',
  VERDE: '#22BAA0',
  LARANJA: '#f2ca4c',
  VERMELHO: '#f25656',
};

const DuesTable = ({ dues }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      name: 'Número',
      selector: (row) => row.due?.numero ?? '-',
    },
    {
      name: 'RUC',
      selector: (row) => row.due?.ruc ?? '-',
    },
    {
      name: 'Situação',
      selector: (row) => row.due?.situacao?.descricao ?? '-',
    },
    {
      name: 'Data da Situação',
      selector: (row) => formatDate(row.due.data_situacao) ?? '-',
    },
  ];

  useEffect(() => {
    if (dues) {
      setRows(dues);
    }
  }, [dues]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default DuesTable;
