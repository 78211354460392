import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { LI, UL } from '../../../Themes/Components/AbstractElements';
import SidebarMenulist from './SidebarMenulist';
import authService from '../../../Services/Auth/authService';

const SidebarMenuItems = () => {
  const MENUITEMS = authService.getMenus();
  const { t } = useTranslation();
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className = 'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-main').className = 'sidebar-main ';
      }
    }
  };

  return (
    <Fragment>
      <UL attrUL={{ className: 'sidebar-links simple-list', id: 'simple-bar' }}>
        <div className='simplebar-wrapper' style={{ margin: '0px' }}>
          <div className='simplebar-height-auto-observer-wrapper'>
            <div className='simplebar-height-auto-observer'></div>
          </div>
          <div className='simplebar-mask'>
            <div className='simplebar-offset'>
              <div
                className='simplebar-content-wrapper custom-scrollbar'
                style={{ height: '100%', overflow: 'hidden scroll' }}
              >
                <div className='simplebar-content p-0'>
                  <LI attrLI={{ className: 'back-btn' }}>
                    <div className='mobile-back text-end'>
                      <span>Voltar</span>
                      <i className='fa fa-angle-right ps-2'></i>
                    </div>
                  </LI>
                  <SidebarMenulist MENUITEMS={MENUITEMS} t={t} toggletNavActive={toggletNavActive} />
                </div>
              </div>
            </div>
          </div>
          <div className='simplebar-placeholder' style={{ width: 'auto', height: '54px' }}></div>
        </div>
        <div className='simplebar-track simplebar-horizontal' style={{ visibility: 'hidden' }}>
          <div className='simplebar-scrollbar' style={{ width: 0, display: 'none' }}></div>
        </div>
        <div className='simplebar-track simplebar-vertical' style={{ visibility: 'visible' }}>
          <div
            className='simplebar-scrollbar'
            style={{
              height: 77,
              transform: 'translate3d(0, 304, 0)',
              display: 'block',
            }}
          ></div>
        </div>
      </UL>
    </Fragment>
  );
};
export default SidebarMenuItems;
