import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import PageLoader from '../../../../Themes/Layout/PageLoader';
import { Breadcrumbs, H3, H4, Span } from '../../../../Themes/Components/AbstractElements';
import { formatDate, formatNumber } from '../../../../utils/formatacoes';
import DocumentosTable from './components/documentosTable';
import UploadButton from '../../../../Themes/Components/UploadButton/uploadButton';
import CadastroProdutosService from '../../../../Services/Cadastro/CadastroProdutosService';
import FabricanteProdutorTable from './components/fabricanteProdutorTable';
import AtributosNCM from '../components/atributosNcm';

const CadastroProdutosView = () => {
  const [produtoData, setProdutoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const view = async () => {
      try {
        const data = await CadastroProdutosService.view(id);

        setProdutoData(data);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        navigate('/cadastro/produtos');
      }
    };

    view();
  }, [id]);

  const handleUploadFile = async (id, files) => {
    try {
      toast.info('Enviando arquivo...');
      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file.file, file.file.name, file.file.identificacao);
      });

      const response = await CadastroProdutosService.uploadDoc(id, formData);

      toast.success(response.message);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <PageLoader loaded={loading} />
      <Breadcrumbs
        mainTitle={`Produto ${produtoData?.nome ?? ''}`}
        title='Produtos'
        parent='Cadastro'
        model={'Cadastro.Produtos'}
        processoId={id}
        favorito={produtoData?.favorito ?? false}
      />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Dados Gerais</H3>
                    <div className='row mb-2'>
                      <div className='col-sm-8'>
                        <H4>Importador/Exportador</H4>
                        <Span>
                          {produtoData?.pessoa.cpfcnpj} - {produtoData?.pessoa.razaosocial}
                        </Span>
                      </div>

                      <div className='col-sm-4'>
                        <H4>Data de Referência</H4>
                        <Span>{formatDate(produtoData?.data_referencia) ?? '-'}</Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-6'>
                        <H4>Denominação do Produto</H4>
                        <Span>{produtoData?.nome ?? '-'}</Span>
                      </div>
                      <div className='col-sm-2'>
                        <H4>Modalidade</H4>
                        <Span>{produtoData?.modalidade ?? '-'}</Span>
                      </div>
                      <div className='col-sm-4'>
                        <H4>Situação Produto</H4>
                        <Span>{produtoData?.status_id ?? '-'}</Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-8'>
                        <H4>Unidades Comercializadas</H4>
                        {produtoData?.produto_unidade_medidas
                          ? produtoData.produto_unidade_medidas
                              .map((item) => `${item.unidade_medida.sigla} - ${item.unidade_medida.descricao}`)
                              .join(', ')
                          : ''}
                      </div>
                      <div className='col-sm-4'>
                        <H4>Código Interno / Part Number / SKU</H4>
                        {produtoData?.produto_codigos
                          ? produtoData.produto_codigos.map((item) => `${item.codigo}`).join(', ')
                          : ''}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-sm-12'>
                        <H4>Descrição Complementar</H4>
                        <Span>{produtoData?.descricao_complementar ?? '-'}</Span>
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-sm-12'>
                        <H4>Observação</H4>
                        <Span>{produtoData?.NcmsLigacoes.descricao}</Span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Portal Único</H3>
                    <div className='row mb-2'>
                      <div className='col-sm-3'>
                        <H4>Código P.U.</H4>
                        <Span>{produtoData?.codigo ?? '-'}</Span>
                      </div>
                      <div className='col-sm-3'>
                        <H4>Versão P.U.</H4>
                        <Span>{produtoData?.versao ?? '-'}</Span>
                      </div>

                      <div className='col-sm-3'>
                        <H4>Ambiente P.U.</H4>
                        <Span>{(produtoData?.ambiente == 1 ? 'Produção' : 'Validação') ?? '-'}</Span>
                      </div>
                      <div className='col-sm-3'>
                        <H4>Situação P.U.</H4>
                        <Span>{produtoData?.situacao ?? '-'}</Span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Classificação Fiscal</H3>
                    <div className='row mb-2'>
                      <div className='col-sm-1'>
                        <H4>NCM</H4>
                        <Span>{produtoData?.NcmsLigacoes.codigo}</Span>
                      </div>
                      <div className='col-sm-8'>
                        <H4>Descrição da NCM</H4>
                        <Span>{produtoData?.NcmsLigacoes.descricao}</Span>
                      </div>
                      <div className='col-sm-3'>
                        <H4>Unidade Estatística</H4>
                        <Span>
                          {produtoData?.NcmsLigacoes.unidade_medida.sigla} -{' '}
                          {produtoData?.NcmsLigacoes.unidade_medida.descricao}
                        </Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-3'>
                        <H4>II</H4>
                        <Span>
                          {produtoData?.NcmsLigacoes.ii !== null
                            ? formatNumber(produtoData?.NcmsLigacoes.ii, 2) + ' %'
                            : '-'}
                        </Span>
                      </div>
                      <div className='col-sm-3'>
                        <H4>IPI</H4>
                        <Span>
                          {produtoData?.NcmsLigacoes.ipi !== null
                            ? formatNumber(produtoData?.NcmsLigacoes.ipi, 2) + ' %'
                            : '-'}
                        </Span>
                      </div>
                      <div className='col-sm-3'>
                        <H4>PIS</H4>
                        <Span>
                          {produtoData?.NcmsLigacoes.pis !== null
                            ? formatNumber(produtoData?.NcmsLigacoes.pis, 2) + ' %'
                            : '-'}
                        </Span>
                      </div>
                      <div className='col-sm-3'>
                        <H4>COFINS</H4>
                        <Span>
                          {produtoData?.NcmsLigacoes.cofins !== null
                            ? formatNumber(produtoData?.NcmsLigacoes.cofins, 2) + ' %'
                            : '-'}
                        </Span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Atributos da NCM</H3>
                    <div className='row mb-2'>
                      <AtributosNCM produtoData={produtoData} tipo='IMPORTACAO' />
                      <AtributosNCM produtoData={produtoData} tipo='EXPORTACAO' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Documentos</H3>

                    <div className='row mb-2'>
                      <div className='col-xl-12 d-flex justify-content-end'>
                        <UploadButton id={id} callback={handleUploadFile} />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <DocumentosTable documentos={produtoData?.produto_documentos} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Fabricante/Produtor</H3>
                    <FabricanteProdutorTable fabricantes={produtoData?.produto_operadores} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CadastroProdutosView;
