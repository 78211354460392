import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatNumber } from '../../../../../utils/formatacoes';

const ValoresTable = ({ processoData }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'descricao',
      name: '',
      selector: (row) => row.descricao,
    },
    {
      id: 'valorMoeda',
      name: 'Valor Moeda',
      right: true,
      selector: (row) => formatNumber(row.valorMoeda) ?? '-',
    },
  ];

  useEffect(() => {
    if (!processoData) return;

    const linhas = [
      {
        descricao: 'VMLE',
        valorMoeda: processoData.total_valor_le,
      },
      {
        descricao: 'Frete',
        valorMoeda: processoData.total_valor_frete,
      },
      {
        descricao: 'Seguro',
        valorMoeda: processoData.total_valor_seguro,
      },
      {
        descricao: 'Outros Valores',
        valorMoeda: processoData.total_valor_outros,
      },
      {
        descricao: 'Descontos',
        valorMoeda: processoData.total_descontos,
      },
      {
        descricao: 'VMCV',
        valorMoeda: processoData.total_valor_cv,
      },
    ];

    setRows(linhas);
  }, [processoData]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} />
    </Fragment>
  );
};

export default ValoresTable;
