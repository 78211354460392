import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../Themes/Components/AbstractElements';
import Chart from '../Components/Chart';
import { DASHBOARD_EXPORTACAO_GRAFICOS } from '../../../Themes/Config/appConfig';
import { Container, Row } from 'reactstrap';

const DashboardExportacao = () => {
  const graficos = JSON.parse(localStorage.getItem(DASHBOARD_EXPORTACAO_GRAFICOS));

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard - Exportação' title='Exportação' parent='Dashboard' />
      <Container fluid className='ecommerce-page'>
        <Row>
          {graficos &&
            graficos.map((graficoData, index) => <Chart key={index} data={graficoData} plugin={'exportacao'} />)}
        </Row>
      </Container>
    </Fragment>
  );
};

export default DashboardExportacao;
