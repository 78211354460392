import { Fragment, useEffect, useState } from 'react';
import { formatNumber } from '../../../../../utils/formatacoes';
import DataTable from 'react-data-table-component';

const ItensTable = ({ processoData }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'item',
      name: 'Item',
      width: '8vh',
      selector: (row) => row.item.toString().padStart(3, '0'),
    },
    {
      id: 'adicao',
      name: 'Adição',
      width: '10vh',
      selector: (row) => row.adicao.toString().padStart(3, '0'),
    },
    {
      id: 'item_adicao',
      name: 'Seq. Merc.',
      width: '13vh',
      selector: (row) => (row.item_adicao ? row.item_adicao.toString().padStart(3, '0') : '-'),
    },
    {
      id: 'partnumber',
      name: 'Part Number',
      selector: (row) => row.part_number ?? '-',
    },
    {
      id: 'ncm',
      name: 'NCM',
      width: '7%',
      selector: (row) => row.produto?.NcmsLigacoes?.codigo ?? '-',
    },
    {
      id: 'nomeProduto',
      name: 'Nome do Produto',
      selector: (row) => row.produto?.nome ?? '-',
    },
    {
      id: 'qtd_comerc',
      name: 'Qtde. Comerc.',
      selector: (row) => formatNumber(row.qtd_comerc, 5),
    },
    {
      id: 'qtd_comerc',
      name: 'Unid. Comerc.',
      selector: (row) => row.unid_comerc,
    },
    {
      id: 'vmcv',
      name: 'VMCV',
      selector: (row) => (row.moeda?.simbolo ?? '') + ' ' + (formatNumber(row.vmcv) ?? '-'),
    },
    {
      id: 'vmle',
      name: 'VMLE',
      selector: (row) => (row.moeda?.simbolo ?? '') + ' ' + (formatNumber(row.vmle_moeda) ?? '-'),
    },
    {
      id: 'frete',
      name: 'Frete',
      selector: (row) =>
        (processoData.taxas[processoData.frete_moeda_id]?.simbolo ?? '') +
        ' ' +
        (formatNumber(row.vlr_frete_moeda) ?? '-'),
    },
    {
      id: 'seguro',
      name: 'Seguro',
      selector: (row) =>
        (processoData.taxas[processoData.seguro_moeda_id]?.simbolo ?? '') +
        ' ' +
        (formatNumber(row.vlr_seguro_moeda) ?? '-'),
    },
    {
      id: 'numeroLi',
      name: 'Número LI',
      selector: (row) => row.li?.numero ?? '-',
    },
  ];

  useEffect(() => {
    if (!processoData) return;

    setRows(processoData.processo_itens);
  }, [processoData]);

  return (
    <Fragment>
      <DataTable
        dense
        columns={columns}
        data={rows}
        pagination
        paginationPerPage={20}
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: 'de',
        }}
      />
    </Fragment>
  );
};

export default ItensTable;
