import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import PageLoader from '../../../../Themes/Layout/PageLoader';
import { Breadcrumbs, H3, H4, Span } from '../../../../Themes/Components/AbstractElements';
import CadastroProdutosService from '../../../../Services/Cadastro/CadastroProdutosService';
import AtributosNCM from '../components/atributosNcm';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CadastroProdutosEdit = () => {
  const [produtoData, setProdutoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [dadosEditados, setDadosEditados] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const edit = async () => {
      try {
        const data = await CadastroProdutosService.edit(id);
        setProdutoData(data);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        navigate('/cadastro/produtos');
      }
    };

    edit();
  }, [id, navigate]);

  const updateDadosEditados = (id, newData) => {
    const existingIndex = dadosEditados.findIndex((item) => item.id === id);
    if (existingIndex !== -1) {
      setDadosEditados((prev) => [
        ...prev.slice(0, existingIndex),
        { id, ...newData },
        ...prev.slice(existingIndex + 1),
      ]);
    } else {
      setDadosEditados((prev) => [...prev, { id, ...newData }]);
    }
  };

  const handleSalvar = async () => {
    setLoading(true);

    try {
      const data = await CadastroProdutosService.salvar(id, dadosEditados);
      setLoading(false);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      navigate('/cadastro/produtos');
    }
  };

  const handleConcluirAnálise = async () => {
    setLoading(true);

    try {
      const data = await CadastroProdutosService.salvar(id, dadosEditados, true);
      setLoading(false);
      toast.success(data.message);
      navigate('/cadastro/produtos');
    } catch (error) {
      toast.error(error.message);
      navigate('/cadastro/produtos');
    }
  };

  return (
    <>
      <PageLoader loaded={loading} />
      <Breadcrumbs
        mainTitle={`Produto ${produtoData?.nome ?? ''}`}
        title='Produtos'
        parent='Cadastro'
        model={'Cadastro.Produtos'}
        processoId={id}
        favorito={produtoData?.favorito ?? false}
      />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Dados Gerais</H3>
                    <div className='row mb-2'>
                      <div className='col-sm-4'>
                        <H4>Denominação do Produto</H4>
                        <Span>{produtoData?.nome ?? '-'}</Span>
                      </div>
                      <div className='col-sm-4'>
                        <H4>Código Interno / Part Number / SKU</H4>
                        {produtoData?.produto_codigos
                          ? produtoData.produto_codigos.map((item) => `${item.codigo}`).join(', ')
                          : ''}
                      </div>
                      <div className='col-sm-2'>
                        <H4>NCM</H4>
                        {produtoData?.NcmsLigacoes ? produtoData.NcmsLigacoes.codigo : ''}
                      </div>
                      <div className='col-sm-2'>
                        <H4>Modalidade</H4>
                        {produtoData?.modalidade ?? ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Atributos da NCM</H3>
                    <div className='row mb-2'>
                      {produtoData?.modalidade === 'IMPORTACAO' && (
                        <AtributosNCM
                          produtoData={produtoData}
                          tipo='IMPORTACAO'
                          updateDadosEditados={updateDadosEditados}
                          isEditMode={true}
                        />
                      )}

                      {produtoData?.modalidade === 'EXPORTACAO' && (
                        <AtributosNCM
                          produtoData={produtoData}
                          tipo='IMPORTACAO'
                          updateDadosEditados={updateDadosEditados}
                          isEditMode={true}
                        />
                      )}
                    </div>
                    <div
                      className='col-sm-12 text-end'
                      style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}
                    >
                      <button className='btn btn-light' onClick={() => navigate('/cadastro/produtos')}>
                        Cancelar
                      </button>
                      <button className='btn btn-primary' onClick={handleSalvar}>
                        Salvar
                      </button>
                      <button className='btn btn-primary' onClick={() => setShowModal(true)}>
                        Concluir Análise
                      </button>
                      <Modal isOpen={showModal}>
                        <ModalHeader>Concluir Análise</ModalHeader>

                        <ModalBody>
                          Deseja concluir a Análise do produto? Após essa ação não é possível editar o produto.
                        </ModalBody>

                        <ModalFooter>
                          <button className='btn btn-light' onClick={() => setShowModal(!showModal)}>
                            Cancelar
                          </button>
                          <button className='btn btn-primary' onClick={handleConcluirAnálise}>
                            Concluir
                          </button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CadastroProdutosEdit;
