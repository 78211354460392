import React, { Fragment } from 'react';

const EmpltyClass = ({ searchIcon, search, message }) => {
  return (
    <Fragment>
      <div
        className={`${
          search
            ? `Typeahead-menu empty-menu ${searchIcon ? 'is-open' : ''}`
            : `Typeahead-menu empty-bookmark ${searchIcon ? 'is-open' : ''}`
        } `}
      >
        <div className='tt-dataset tt-dataset-0'>
          <div className='EmptyMessage'>{message}</div>
        </div>
      </div>
    </Fragment>
  );
};
export default EmpltyClass;
