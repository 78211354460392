import { api } from '../api';

const TrackingEmbarquesService = {
  index: async (page = 1, limit = 20, sortColumn = null, sortOrder = null, filtros = {}, favoritos = false) => {
    try {
      let url = `tracking/embarques/index?page=${page}&limit=${limit}`;

      if (sortColumn && sortOrder) {
        url += `&sortColumn=${sortColumn}&sortOrder=${sortOrder}`;
      }

      if (filtros) {
        Object.keys(filtros).forEach((campo) => {
          url += `&${campo}=${filtros[campo]}`;
        });
      }

      if (favoritos) {
        url += `&favoritos=true`;
      }

      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao carregar a listagem de embraques.');
    }
  },
  view: async (id) => {
    try {
      const response = await api.get(`tracking/embarques/view/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao carregar a visualização do embarques.');
    }
  },
};

export default TrackingEmbarquesService;
