import React from 'react';
import Select from 'react-select';

const UploadPreview = ({ meta, fileWithMeta: { remove }, onOptionChange, selectedOption, options = [] }) => {
  const { name, id } = meta;

  const handleChange = (selectedOption) => {
    onOptionChange(id, selectedOption);
  };

  return (
    <div className='dzu-previewContainer'>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, width: '100%' }}>
        <span className='dzu-previewFileName' style={{ width: '350px' }}>
          {name}
        </span>
        <div style={{ width: '300px' }}>
          <Select
            style={{ marginLeft: 10 }}
            options={Object.entries(options).map(([key, value]) => ({
              id: key,
              label: value,
            }))}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={selectedOption}
            onChange={handleChange}
            placeholder='Selecione o tipo do documento'
          />
        </div>
      </div>
      <i
        className='icofont icofont-close'
        onClick={remove}
        style={{ fontSize: 20, cursor: 'pointer' }}
        title='Visualizar processo'
      />
    </div>
  );
};

export default UploadPreview;
