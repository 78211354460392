import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import ImportacaoProcessosService from '../../../../../Services/Importacao/ImportacaoProcessosService';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../../../utils/files';
import { formatDate } from '../../../../../utils/formatacoes';

const DocumentosTable = ({ documentos }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'identificacao',
      name: 'Identificação',
      selector: (row) => row.identificacao ?? '-',
    },
    {
      id: 'tipo',
      name: 'Tipo',
      selector: (row) => row.TipoDocumentos?.descricao ?? '-',
    },
    {
      id: 'usuario',
      name: 'Enviado por',
      selector: (row) => row.upload_usuario?.nome ?? '-',
    },
    {
      id: 'dataEnvio',
      name: 'Data do Envio',
      selector: (row) => (row.upload_data ? formatDate(row.upload_data, true) : '-'),
    },
    {
      id: 'data',
      name: 'Data do Documento',
      selector: (row) => formatDate(row.data, false) ?? '-',
    },
    {
      name: 'Ações',
      id: 'actions',
      center: true,
      compact: true,
      width: '10vh',
      cell: (row) => (
        <i
          className='icofont icofont-download'
          onClick={() => handleDownloadClick(row.id)}
          style={{ fontSize: 20, cursor: 'pointer' }}
          title='Baixar documento'
        />
      ),
    },
  ];

  useEffect(() => {
    if (!documentos) return;

    const linhas = documentos.map((doc) => {
      return {
        ...doc,
      };
    });

    setRows(linhas);
  }, [documentos]);

  const handleDownloadClick = async (id) => {
    try {
      const fileData = await ImportacaoProcessosService.downloadDoc(id);

      if (fileData && fileData.file) {
        downloadFile(fileData);
      } else {
        toast.error('Erro ao fazer o download do arquivo.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default DocumentosTable;
