import { Fragment, useEffect, useState } from 'react';
import { formatNumber } from '../../../../../utils/formatacoes';
import DataTable from 'react-data-table-component';

const AdicoesTable = ({ processoData }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'adicao',
      name: 'Adição',
      width: '3.8vw',
      selector: (row) => row.adicao.toString().padStart(3, '0'),
    },
    {
      id: 'ncm',
      name: 'NCM',
      width: '5vw',
      selector: (row) => row.produto?.NcmsLigacoes?.codigo ?? '-',
    },
    {
      id: 'qtde_itens',
      name: 'Nº Itens',
      width: '4vw',
      selector: (row) => row.qtde_itens ?? '-',
    },
    {
      id: 'qtd_estat',
      name: 'Qtde. Estat.',
      selector: (row) => formatNumber(row.qtd_estat, 5) ?? '-',
    },
    {
      id: 'unid_estat',
      name: 'Unid. Estat.',
      selector: (row) => row.produto?.NcmsLigacoes?.unidade_medida?.sigla ?? '-',
    },
    {
      id: 'qtd_comerc',
      name: 'Qtde. Comerc.',
      selector: (row) => formatNumber(row.qtd_comerc) ?? '-',
    },
    {
      id: 'unid_comerc',
      name: 'Unid. Comerc.',
      selector: (row) => row.unid_comerc ?? '-',
    },
    {
      id: 'peso_liquido',
      name: 'Peso Líquido',
      selector: (row) => formatNumber(row.peso_liquido, 5) ?? '-',
    },
    {
      id: 'vmcv',
      name: 'VMCV',
      selector: (row) => (processoData.taxas[row.moeda_id]?.simbolo ?? '') + ' ' + (formatNumber(row.vmcv) ?? '-'),
    },
    {
      id: 'vmle',
      name: 'VMLE',
      selector: (row) =>
        (processoData.taxas[row.moeda_id]?.simbolo ?? '') + ' ' + (formatNumber(row.vmle_moeda) ?? '-'),
    },
    {
      id: 'frete',
      name: 'Frete',
      selector: (row) =>
        (processoData.taxas[processoData.frete_moeda_id]?.simbolo ?? '') +
        ' ' +
        (formatNumber(row.vlr_frete_moeda) ?? '-'),
    },
    {
      id: 'seguro',
      name: 'Seguro',
      selector: (row) =>
        (processoData.taxas[processoData.seguro_moeda_id]?.simbolo ?? '') +
        ' ' +
        (formatNumber(row.vlr_seguro_moeda) ?? '-'),
    },
    {
      id: 'vlr_aduaneiro',
      name: 'Valor Aduaneiro',
      selector: (row) => 'R$ ' + (formatNumber(row.vlr_aduaneiro) ?? '-'),
    },
    {
      id: 'ii',
      name: 'II A Recolher',
      width: '8vw',
      selector: (row) =>
        'R$ ' + (formatNumber(row.ii_vlr_recolher) ?? '-') + ' (' + (formatNumber(row.ii_aliquota) ?? '-') + '%)',
    },
    {
      id: 'ipi',
      name: 'IPI A Recolher',
      width: '8vw',
      selector: (row) =>
        'R$ ' + (formatNumber(row.ipi_vlr_recolher) ?? '-') + ' (' + (formatNumber(row.ipi_aliquota) ?? '-') + '%)',
    },
    {
      id: 'pis',
      name: 'PIS A Recolher',
      width: '8vw',
      selector: (row) =>
        'R$ ' + (formatNumber(row.pis_vlr_recolher) ?? '-') + ' (' + (formatNumber(row.pis_aliquota) ?? '-') + '%)',
    },
    {
      id: 'cofins',
      name: 'COFINS A Recolher',
      width: '8vw',
      selector: (row) =>
        'R$ ' +
        (formatNumber(row.cofins_vlr_recolher) ?? '-') +
        ' (' +
        (formatNumber(row.cofins_aliquota) ?? '-') +
        '%)',
    },
  ];

  useEffect(() => {
    if (!processoData) return;

    setRows(processoData.adicoes);
  }, [processoData]);

  return (
    <Fragment>
      <DataTable
        dense
        columns={columns}
        data={rows}
        pagination
        paginationPerPage={20}
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: 'de',
        }}
      />
    </Fragment>
  );
};

export default AdicoesTable;
