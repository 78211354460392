import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import CustomContext from '../../../../_helper/Customizer';
import MenuItemsGroup from './MenuItemsGroup';

const ListOfMenu = ({
  searchToggle,
  searchBar,
  searchValue,
  removeFix,
  setBookmarkItems,
  bookmarkItems,
  searchResult,
}) => {
  const { IsOpen } = useContext(CustomContext);

  return (
    <Fragment>
      <div
        className={`${
          searchBar
            ? `Typeahead-menu custom-scrollbar ${searchToggle ? 'is-open' : ''}`
            : `filled-bookmark Typeahead-menu ${IsOpen ? ' is-open' : ''} custom-scrollbar`
        } `}
        id='search-outer'
      >
        {searchResult &&
          Object.keys(searchResult).map((key) => {
            return <MenuItemsGroup key={key} group={searchResult[key]} removeFix={removeFix} />;
          })}
      </div>
    </Fragment>
  );
};

export default ListOfMenu;
