import { useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../Themes/Components/AbstractElements';
import DataTable from 'react-data-table-component';
import { formatValue } from '../../../utils/formatacoes';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableActions from '../../../Themes/Components/TableActions';
import { format } from 'date-fns';
import ExportacaoProcessosService from '../../../Services/Exportacao/ExportacaoProcessosService';
import Loading from '../../../Themes/Components/Loading';
import ConfiguracaoUsuariosService from '../../../Services/Configuracao/ConfiguracaoUsuariosService';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import EtapasChart from '../../Dashboard/Components/EtapasChart';

const ExportacaoProcessosIndex = () => {
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
    total: 0,
    page: 1,
    limit: 20,
    sortColumn: null,
    sortOrder: null,
    filtros: {},
  });
  const [loading, setLoading] = useState(true);
  const [filtrosUsuario, setFiltrosUsuario] = useState({});
  const [graficosData, setGraficosData] = useState(null);
  const [loadingGraf, setLoadingGraf] = useState(true);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [eventoCondicoesId, setEventoCondicoesId] = useState(null);
  const inicialFavoritos = state ? state.favoritos : false;
  const [onlyFavoritos, setOnlyFavoritos] = useState(inicialFavoritos);

  useEffect(() => {
    if (state?.eventoCondicoesId) {
      setEventoCondicoesId(state.eventoCondicoesId);
    }
  }, [state]);

  useEffect(() => {
    const index = async () => {
      setLoading(true);
      const handleViewClick = (id) => navigate(`/exportacao/processos/view/${id}`);

      try {
        const data = await ExportacaoProcessosService.index(
          eventoCondicoesId,
          tableData.page,
          tableData.limit,
          tableData.sortColumn,
          tableData.sortOrder,
          tableData.filtros,
          onlyFavoritos,
        );

        const colunas = [
          {
            name: '',
            id: 'actions',
            center: true,
            compact: true,
            width: '3vw',
            cell: (row) => (
              <i
                className='icofont icofont-eye-alt'
                onClick={() => handleViewClick(row['Processos.id'])}
                style={{ fontSize: 20, cursor: 'pointer' }}
                title='Visualizar processo'
              />
            ),
          },
        ];

        data.columns.forEach((coluna) => {
          const { label, campo, tipo, casasDecimais } = coluna;

          colunas.push({
            name: label,
            id: campo,
            sortable: true,
            right: tipo === 'decimal',
            wrap: true,
            selector: (row) => {
              const coluna = `Processos.${label}`;
              return row[coluna] ? formatValue(row[coluna], tipo, casasDecimais) : '-';
            },
          });
        });

        setTableData({
          columns: colunas,
          rows: data.rows,
          total: data.pageInfo.total,
        });

        setFiltrosUsuario(data.filtrosUsuario);
      } catch (error) {
        toast.error(error.message);
      }

      setLoading(false);
    };

    index();
  }, [eventoCondicoesId, onlyFavoritos]);

  useEffect(() => {
    const getGraficoSituacoes = async () => {
      setLoadingGraf(true);
      const graficos = [];

      for (let i = 1; i <= 4; i++) {
        const graficoData = await ConfiguracaoUsuariosService.getGrafico(`exportacao_grafico_etapas_${i}`);
        graficos.push(graficoData);
      }

      setGraficosData(graficos);
      setLoadingGraf(false);
    };

    if (!graficosData) {
      getGraficoSituacoes();
    }
  }, [graficosData]);

  const handlePageChange = async (page, limit) => {
    try {
      setLoading(true);

      const data = await ExportacaoProcessosService.index(
        eventoCondicoesId,
        page,
        limit,
        tableData.sortColumn,
        tableData.sortOrder,
        tableData.filtros,
        onlyFavoritos,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        page,
        limit,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };

  const handleSort = async (column, order) => {
    try {
      setLoading(true);

      const data = await ExportacaoProcessosService.index(
        eventoCondicoesId,
        tableData.page,
        tableData.limit,
        column.id,
        order,
        tableData.filtros,
        onlyFavoritos,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        sortColumn: column.id,
        sortOrder: order,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };

  const handleSubmitFilters = async (values) => {
    if (Object.keys(values).length === 0) {
      toast.warning('Informe os filtros.');
      return;
    }

    const filtros = {};

    Object.keys(values).forEach((campo) => {
      let value = values[campo];

      if (value instanceof Date) {
        value = format(value, 'dd/MM/yyyy');
      }

      if (value.length > 0) {
        filtros[campo] = value;
      }
    });

    setLoading(true);

    try {
      const data = await ExportacaoProcessosService.index(
        eventoCondicoesId,
        tableData.page,
        tableData.limit,
        tableData.sortColumn,
        tableData.sortOrder,
        filtros,
        onlyFavoritos,
      );

      setTableData({
        ...tableData,
        rows: data.rows,
        total: data.pageInfo.total,
        filtros,
      });
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };

  const clearTable = async () => {
    setTableData({
      columns: tableData.columns,
      rows: [],
      total: 0,
      page: 1,
      limit: 20,
      sortColumn: null,
      sortOrder: null,
      filtros: {},
    });
  };

  return (
    <>
      <Breadcrumbs mainTitle='Processos' title='Processos' parent='Exportação' />
      <Container fluid={true}>
        <Row>
          {loadingGraf ? (
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Loading />
                </CardBody>
              </Card>
            </Col>
          ) : (
            graficosData.map((data, i) => (
              <Col key={i} md={3}>
                <EtapasChart key={i} data={data} setEventoCondicoesId={(eventoId) => setEventoCondicoesId(eventoId)} />
              </Col>
            ))
          )}
        </Row>
      </Container>

      <Container fluid={true}>
        <Row>
          <Col sm={12}>
            <Card>
              <CardHeader className='pb-0'>
                <TableActions
                  dataFilters={filtrosUsuario}
                  showFiltroFavoritos={true}
                  onlyFavoritos={onlyFavoritos}
                  clearTable={clearTable}
                  setOnlyFavoritos={setOnlyFavoritos}
                  handleFilterSubmit={handleSubmitFilters}
                />
              </CardHeader>
              <CardBody className='mt-0 pt-0 m-b-0'>
                <DataTable
                  noDataComponent='Nenhum registro encontrado.'
                  data={tableData.rows}
                  columns={tableData.columns}
                  striped={true}
                  responsive={true}
                  highlightOnHover={true}
                  progressPending={loading}
                  progressComponent={<Loading />}
                  pagination
                  paginationServer={true}
                  paginationPerPage={tableData.limit}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                    rangeSeparatorText: 'de',
                  }}
                  onChangePage={(page) => handlePageChange(page, tableData.limit)}
                  onChangeRowsPerPage={(limit, page) => handlePageChange(page, limit)}
                  paginationTotalRows={tableData.total}
                  sortServer
                  onSort={handleSort}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ExportacaoProcessosIndex;
