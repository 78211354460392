import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Home } from 'react-feather';
import { OL, LI, H3 } from '../AbstractElements';
import ConfiguracaoUsuariosService from '../../../Services/Configuracao/ConfiguracaoUsuariosService';
import { toast } from 'react-toastify';

const BreadcrumbProcessos = (props) => {
  const [favorito, setFavorito] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    setFavorito(props.favorito);
  }, [props.favorito]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const salvarFavorito = async () => {
        try {
          await ConfiguracaoUsuariosService.setFavorito(props.model, props.processoId, favorito);
        } catch (error) {
          toast.error(error);
        }
      };

      salvarFavorito();
    }
  }, [favorito, props.model, props.processoId]);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className='page-title'>
          <Row>
            <Col xs='6'>
              <a
                href='#'
                style={{
                  fontSize: 20,
                  marginRight: '0.5vw',
                }}
                className={!favorito ? 'txt-dark-gray' : 'txt-yellow'}
                title={favorito ? 'Remover dos favoritos' : 'Favoritar processo'}
                onClick={(e) => {
                  e.preventDefault();
                  setFavorito(!favorito);
                }}
              >
                <i className='icofont icofont-star'></i>
              </a>
              <H3>{props.mainTitle}</H3>
            </Col>
            <Col xs='6'>
              <OL attrOL={{ className: 'breadcrumb' }}>
                <LI attrLI={{ className: 'breadcrumb-item' }}>
                  <Link to={`/dashboards/importacao`}>
                    <Home />
                  </Link>
                </LI>
                <LI attrLI={{ className: 'breadcrumb-item' }}>{props.parent}</LI>
                {props.subParent ? <LI attrLI={{ className: 'breadcrumb-item' }}>{props.subParent}</LI> : ''}
                <LI attrLI={{ className: 'breadcrumb-item active' }}>{props.title}</LI>
              </OL>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default BreadcrumbProcessos;
