import React, { Fragment } from 'react';

const Span = (props) => {
  return (
    <Fragment>
      {props.innerHtml ? <span dangerouslySetInnerHTML={{ __html: props.innerHtml }} /> : ''}
      <span {...props.attrPara}>{props.children}</span>
    </Fragment>
  );
};

export default Span;
