import { Fragment, useEffect, useState } from 'react';
import { Card, Col } from 'reactstrap';
import Loading from '../../../Themes/Components/Loading';
import ConfiguracaoUsuariosService from '../../../Services/Configuracao/ConfiguracaoUsuariosService';
import TableChart from './TableChart';
import EtapasChart from '../Components/EtapasChart';
import LinhasChart from '../Components/LinhasChart';
import DoughnutChart from './DoughnutChart';
import TotaisChart from './TotaisChart';
import FavoritosChart from './FavoritosChart';
import { toast } from 'react-toastify';
import ImportacaoProcessosService from '../../../Services/Importacao/ImportacaoProcessosService';
import ExportacaoProcessosService from '../../../Services/Exportacao/ExportacaoProcessosService';

const Chart = ({ data, plugin }) => {
  const [loading, setLoading] = useState(true);
  const [graficoData, setGraficoData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        let responseData = null;

        if (!data.id.includes('_favoritos')) {
          responseData = await ConfiguracaoUsuariosService.getGrafico(data.id);
        } else {
          switch (plugin) {
            case 'importacao':
              responseData = await ImportacaoProcessosService.index(null, 1, 5, 'modified', 'DESC', null, true);
              responseData.tipo = 'favoritos';
              break;
            case 'exportacao':
              responseData = await ExportacaoProcessosService.index(null, 1, 5, 'modified', 'DESC', null, true);
              responseData.tipo = 'favoritos';
              break;
          }
        }

        setGraficoData(responseData);
        setLoading(false);
      } catch (e) {
        toast.error('Erro ao carregar o gráfico.');
        setLoading(false);
      }
    };

    getData();
  }, [data, plugin]);

  if (loading) {
    return (
      <Fragment>
        <Col sm={data.tamanho}>
          <Card>
            <Loading />
          </Card>
        </Col>
      </Fragment>
    );
  }

  let chart = null;

  if (!graficoData) {
    return <></>;
  }

  switch (graficoData.tipo) {
    case 'etapa':
      chart = <EtapasChart data={graficoData} index={`/${plugin}/processos`} />;
      break;
    case 'linha':
      chart = <LinhasChart data={graficoData} />;
      break;
    case 'tabela':
      chart = <TableChart data={graficoData} />;
      break;
    case 'doughnut':
      chart = <DoughnutChart data={graficoData} />;
      break;
    case 'total':
      chart = <TotaisChart data={graficoData} />;
      break;
    case 'favoritos':
      chart = <FavoritosChart data={graficoData} plugin={plugin} />;
      break;
  }

  return (
    <Fragment>
      <Col md={data.tamanho}>{chart}</Col>
    </Fragment>
  );
};

export default Chart;
