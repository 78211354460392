import { Fragment, useEffect, useState } from 'react';
import { formatNumber } from '../../../../../utils/formatacoes';
import DataTable from 'react-data-table-component';

const ResumoFinanceiroTable = ({ totaisLancamentos }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'soma_numerarios',
      name: 'Numerários',
      selector: (row) => formatNumber(row.soma_numerarios) ?? '-',
    },
    {
      id: 'soma_despesas_comissaria',
      name: 'Despesas Comissária',
      selector: (row) => formatNumber(row.soma_despesas_comissaria) ?? '-',
    },
    {
      id: 'saldo_numerario',
      name: 'Saldo Numerário',
      selector: (row) => formatNumber(row.soma_numerarios - row.soma_despesas_comissaria) ?? '-',
    },
    {
      id: 'soma_despesas_importador',
      name: 'Despesas Importador',
      selector: (row) => formatNumber(row.soma_despesas_importador) ?? '-',
    },
    {
      id: 'total_despesas',
      name: 'Total Despesas',
      selector: (row) => formatNumber(row.soma_despesas_importador + row.soma_despesas_comissaria) ?? '-',
    },
  ];

  useEffect(() => {
    if (!totaisLancamentos) return;

    setRows([totaisLancamentos]);
  }, [totaisLancamentos]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default ResumoFinanceiroTable;
