import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate, formatNumber } from '../../../../../utils/formatacoes';

const DespesasTable = ({ despesas, responsabilidade }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'data_cadastro',
      name: 'Data Cadastro',
      selector: (row) => formatDate(row.data_cadastro, false) ?? '-',
    },
    {
      id: 'nome',
      name: 'Descrição',
      selector: (row) => row.nome ?? '-',
    },
    {
      id: 'data_vencimento',
      name: 'Data Vencimento',
      selector: (row) => formatDate(row.data_vencimento, false) ?? '-',
    },
    {
      id: 'data_recebimento',
      name: 'Data Pagamento',
      selector: (row) => formatDate(row.data_recebimento, false) ?? '-',
    },
    {
      id: 'valor',
      name: 'Valor R$',
      right: true,
      selector: (row) => (row.situacao === 3 ? formatNumber(row.valor_liquido) : formatNumber(row.valor_real) ?? '-'),
    },
    {
      id: 'situacao',
      name: 'Situação',
      selector: (row) => row.situacao ?? '-',
    },
  ];

  useEffect(() => {
    if (!despesas) return;

    const despesasFiltradas = [];

    Object.keys(despesas).forEach((key) => {
      const despesa = despesas[key];

      if (despesas[key].responsabilidade === responsabilidade) {
        despesasFiltradas.push(despesa);
      }
    });

    setRows(despesasFiltradas);
  }, [despesas, responsabilidade]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default DespesasTable;
