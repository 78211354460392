import {
  DASHBOARD_EXPORTACAO_GRAFICOS,
  DASHBOARD_IMPORTACAO_GRAFICOS,
  DASHBOARD_INICIAL,
  LOGOTIPO_CLIENTE,
  MENUS,
  TIPO_DOC_EXPO_OBRIGATORIO,
  TIPO_DOC_IMPO_OBRIGATORIO,
  TOKEN_KEY,
  USER_KEY,
} from '../../Themes/Config/appConfig';
import { api } from '../api';

const login = async (email, password) => {
  const { data } = await api.post('configuracao/usuarios/login', {
    login: email,
    password: password,
  });

  if (data.token) {
    setToken(data.token);
    const username = JSON.stringify(data.usuario.nome);
    localStorage.setItem(USER_KEY, username);
    localStorage.setItem(MENUS, JSON.stringify(data.usuario.menu));
  }

  if (data.pessoa.identificacao.logotipo) {
    localStorage.setItem(LOGOTIPO_CLIENTE, data.pessoa.identificacao.logotipo);
  } else {
    localStorage.removeItem(LOGOTIPO_CLIENTE);
  }

  if (data.pessoa.configuracoes.dashboard_inicial) {
    localStorage.setItem(DASHBOARD_INICIAL, data.pessoa.configuracoes.dashboard_inicial);
  } else {
    localStorage.setItem(DASHBOARD_INICIAL, '/dashboards/importacao');
  }

  if (data.pessoa.configuracoes.exportacao_upload_tipo_doc_obrigatorio) {
    localStorage.setItem(TIPO_DOC_EXPO_OBRIGATORIO, data.pessoa.configuracoes.exportacao_upload_tipo_doc_obrigatorio);
  }

  if (data.pessoa.configuracoes.importacao_upload_tipo_doc_obrigatorio) {
    localStorage.setItem(TIPO_DOC_IMPO_OBRIGATORIO, data.pessoa.configuracoes.importacao_upload_tipo_doc_obrigatorio);
  }

  if (data.pessoa.configuracoes.graficos.exportacao) {
    localStorage.setItem(DASHBOARD_EXPORTACAO_GRAFICOS, JSON.stringify(data.pessoa.configuracoes.graficos.exportacao));
  }

  if (data.pessoa.configuracoes.graficos.importacao) {
    localStorage.setItem(DASHBOARD_IMPORTACAO_GRAFICOS, JSON.stringify(data.pessoa.configuracoes.graficos.importacao));
  }

  return data;
};

const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const getMenus = () => {
  const storedData = localStorage.getItem(MENUS);
  if (storedData) {
    try {
      const parsedData = JSON.parse(storedData);
      const menusArray = Object.keys(parsedData).map((key) => parsedData[key]);
      return menusArray;
    } catch (error) {
      console.error('Error parsing stored data:', error);
    }
  }
  return [];
};

const getUserName = () => {
  const usernameLS = localStorage.getItem(USER_KEY);
  if (usernameLS) {
    const username = JSON.parse(usernameLS);
    return username;
  }
  return null;
};

const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(LOGOTIPO_CLIENTE);
  localStorage.removeItem(MENUS);
  localStorage.removeItem(DASHBOARD_INICIAL);
  localStorage.removeItem(TIPO_DOC_EXPO_OBRIGATORIO);
  localStorage.removeItem(TIPO_DOC_IMPO_OBRIGATORIO);
  localStorage.removeItem(DASHBOARD_EXPORTACAO_GRAFICOS);
  localStorage.removeItem(DASHBOARD_IMPORTACAO_GRAFICOS);
  localStorage.removeItem('mix_background_layout');
};

export default {
  login,
  logout,
  getToken,
  getMenus,
  getUserName,
};
