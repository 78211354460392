import { useEffect, useState } from 'react';
import ImportacaoProcessosService from '../../../../Services/Importacao/ImportacaoProcessosService';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import PageLoader from '../../../../Themes/Layout/PageLoader';
import { H3, H4, Span } from '../../../../Themes/Components/AbstractElements';
import { formatDate, formatNumber } from '../../../../utils/formatacoes';
import ValoresTable from './components/valoresTable';
import ImpostosTable from './components/impostosTable';
import DocumentosTable from './components/documentosTable';
import UploadButton from '../../../../Themes/Components/UploadButton/uploadButton';
import ItensTable from './components/itensTable';
import EmbalagensTable from './components/embalagensTable';
import DespesasTable from './components/despesasTable';
import NumerariosTable from './components/numerariosTable';
import EventsTimeline from '../../../../Themes/Components/EventsTimeline';
import ResumoFinanceiroTable from './components/resumoFinanceiroTable';
import BreadcrumbProcessos from '../../../../Themes/Components/BreadcrumbProcessos';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AdicoesTable from './components/adicoesTable';
import { TIPO_DOC_IMPO_OBRIGATORIO } from '../../../../Themes/Config/appConfig';

const ImportacaoProcessosView = () => {
  const [processoData, setProcessoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showItens, setShowItens] = useState(false);
  const [showAdicoes, setShowAdicoes] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const docRequired = localStorage.getItem(TIPO_DOC_IMPO_OBRIGATORIO);

  useEffect(() => {
    const view = async () => {
      try {
        const data = await ImportacaoProcessosService.view(id);

        setProcessoData(data);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        navigate('/importacao/processos');
      }
    };

    view();
  }, [id]);

  const camposViaTransporte = (viaTransproteId) => {
    switch (viaTransproteId) {
      case 1:
        return (
          <>
            <div className='col-md-2'>
              <H4>CE Mercante</H4>
              <Span>{processoData?.transporte_conhecimento_ce_mercante ?? '-'}</Span>
            </div>
          </>
        );
      case 4:
      case 6:
        return (
          <>
            <div className='col-md-2'>
              <H4>House</H4>
              <Span>{processoData?.transporte_conhecimento_identificacao ?? '-'}</Span>
            </div>

            <div className='col-md-2'>
              <H4>Master</H4>
              <Span>{processoData?.transporte_conhecimento_id_master ?? '-'}</Span>
            </div>
          </>
        );
    }
  };

  const handleUploadFile = async (id, files) => {
    try {
      const fileWithoutType = files.find((file) => !file.tipoDocumento || !file.tipoDocumento.id);

      if (docRequired && fileWithoutType) {
        return toast.error('O tipo de documento é obrigatório para todos os arquivos!');
      }

      toast.info('Enviando arquivo...');
      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file.file, file.file.name, file.file.identificacao);
        formData.append(
          `tipoDocumentos[${index}]`,
          file.tipoDocumento && file.tipoDocumento.id ? file.tipoDocumento.id : null,
        );
      });

      const response = await ImportacaoProcessosService.uploadDoc(id, formData);

      toast.success(response.message);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <PageLoader loaded={loading} />
      <BreadcrumbProcessos
        mainTitle={`Processo ${processoData?.ref_cliente ?? ''}`}
        title='Processos'
        parent='Importação'
        model={'Importacao.Processos'}
        processoId={id}
        favorito={processoData?.favorito ?? false}
      />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-9'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Dados Gerais</H3>

                    <div className='row mb-2'>
                      <div className='col-sm-2'>
                        <H4>Número</H4>
                        <Span>{processoData?.numero}</Span>
                      </div>

                      <div className='col-sm-8'>
                        <H4>Ref. Cliente</H4>
                        <Span>{processoData?.ref_cliente}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Data Abertura</H4>
                        <Span>{formatDate(processoData?.data_abertura, false)}</Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-6'>
                        <H4>Cliente</H4>
                        <Span>
                          {processoData?.pessoa
                            ? processoData.pessoa.cpfcnpj + ' - ' + processoData.pessoa.fantasia
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-6'>
                        <H4>Importador</H4>
                        <Span>
                          {processoData?.importador
                            ? processoData.importador.cpfcnpj + ' - ' + processoData.importador.fantasia
                            : '-'}
                        </Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-2'>
                        <H4>Número {processoData?.tipo_documento}</H4>
                        <Span>{processoData?.numero_registro ?? '-'}</Span>
                      </div>

                      <div className='col-sm-1'>
                        <H4>Canal</H4>
                        <Span>{processoData?.canal ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Data Registro {processoData?.tipo_documento}</H4>
                        <Span>{formatDate(processoData?.data_registro) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Data Desembaraço</H4>
                        <Span>{formatDate(processoData?.data_desembaraco) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Protocolo {processoData?.tipo_documento}</H4>
                        <Span>{processoData?.protocolo ?? '-'}</Span>
                      </div>
                    </div>

                    <div className='row'>{camposViaTransporte(processoData?.via_transporte_id)}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Valores Totais</H3>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <ValoresTable processoData={processoData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Impostos</H3>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <ImpostosTable processoData={processoData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Documentos</H3>

                    <div className='row mb-2'>
                      <div className='col-xl-12 d-flex justify-content-end'>
                        <UploadButton id={id} options={processoData?.selectDocumentos} callback={handleUploadFile} />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <DocumentosTable documentos={processoData?.processo_documentos} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Adições/Itens</H3>

                    <div className='row'>
                      <div className='col-xl-6'>
                        <Button
                          style={{
                            width: '100%',
                          }}
                          color='primary'
                          onClick={() => setShowAdicoes(true)}
                        >
                          Visualizar Adições
                        </Button>

                        <Modal isOpen={showAdicoes} fullscreen>
                          <ModalHeader>Adições</ModalHeader>

                          <ModalBody>
                            <AdicoesTable processoData={processoData} />
                          </ModalBody>

                          <ModalFooter>
                            <Button color='primary' onClick={() => setShowAdicoes(!showAdicoes)}>
                              Fechar
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>

                      <div className='col-xl-6'>
                        <Button
                          style={{
                            width: '100%',
                          }}
                          color='primary'
                          onClick={() => setShowItens(true)}
                        >
                          Visualizar Itens
                        </Button>

                        <Modal isOpen={showItens} fullscreen>
                          <ModalHeader>Itens</ModalHeader>

                          <ModalBody>
                            <ItensTable processoData={processoData} />
                          </ModalBody>

                          <ModalFooter>
                            <Button color='primary' onClick={() => setShowItens(!showItens)}>
                              Fechar
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Carga</H3>

                    <div className='row mb-2'>
                      <div className='col-sm-6'>
                        <H4>Unidade de Despacho</H4>
                        <Span>
                          {processoData?.unidade_localizacao_rfb
                            ? processoData.unidade_localizacao_rfb.codigo +
                              ' - ' +
                              processoData.unidade_localizacao_rfb.descricao
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-6'>
                        <H4>País de Procedência</H4>
                        <Span>{processoData?.carga_pais?.nome ?? '-'}</Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-3'>
                        <H4>Previsão de Embarque (ETD)</H4>
                        <Span>{formatDate(processoData?.transporte_previsao_embarque, false) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-3'>
                        <H4>Previsão de Chegada (ETA)</H4>
                        <Span>{formatDate(processoData?.transporte_previsao_chegada, false) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Data Chegada</H4>
                        <Span>{formatDate(processoData?.carga_data_chegada, false) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Peso Bruto</H4>
                        <Span>{formatNumber(processoData?.carga_peso_bruto, 5) ?? '-'}</Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>Peso Liquido</H4>
                        <Span>{formatNumber(processoData?.carga_peso_liquido, 5) ?? '-'}</Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-6'>
                        <H4>Unidade de Entrada/Destino Final</H4>
                        <Span>
                          {processoData?.unidade_entrada_rfb
                            ? processoData.unidade_entrada_rfb.codigo +
                              ' - ' +
                              processoData.unidade_entrada_rfb.descricao
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-6'>
                        <H4>Recinto de Entrada</H4>
                        <Span>
                          {processoData?.entrada_recinto_rfb
                            ? processoData.entrada_recinto_rfb.codigo +
                              ' - ' +
                              processoData.entrada_recinto_rfb.descricao
                            : '-'}
                        </Span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-sm-6'>
                        <H4>Recinto de Armazenagem</H4>
                        <Span>
                          {processoData?.armazem_recinto_rfb
                            ? processoData.armazem_recinto_rfb.codigo +
                              ' - ' +
                              processoData.armazem_recinto_rfb.descricao
                            : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-6'>
                        <H4>Setor de Armazenagem</H4>
                        <Span>
                          {processoData?.setor ? processoData.setor.setor + ' - ' + processoData.setor.descricao : '-'}
                        </Span>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-12'>
                        <H4>Embalagens/Volumes</H4>
                        <EmbalagensTable embalagens={processoData?.processo_embalagens} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Financeiro</H3>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <H4>Resumo Financeiro</H4>
                        <ResumoFinanceiroTable totaisLancamentos={processoData?.totais_lancamentos} />
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <H4>Despesas Pagas pelo Importador</H4>
                        <DespesasTable despesas={processoData?.processo_despesas} responsabilidade={2} />
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-xl-12'>
                        <H4>Despesas Pagas pela Comissária</H4>
                        <DespesasTable despesas={processoData?.processo_despesas} responsabilidade={1} />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-12'>
                        <H4>Numerários</H4>
                        <NumerariosTable numerarios={processoData?.processo_numerarios} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-3'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Eventos</H3>
                    <EventsTimeline eventos={processoData?.processo_eventos} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportacaoProcessosView;
