import React, { Fragment } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { H5, P } from '../../../Themes/Components/AbstractElements';
import HeaderCard from './HeaderCard';
import ConfigDB from '../../../Themes/Config/ThemeConfig';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

const TableChart = ({ data }) => {
  const IconColumn = ({ row }) => {
    switch (row.iconType) {
      case 'flag-icon':
        return (
          <div className='flex-shrink-0'>
            <div className='icon'>
              <i className={`flag-icon m-0 flag-icon-${row.title}`}></i>
            </div>
          </div>
        );
      case 'text':
        return (
          <div className='flex-shrink-0'>
            <div className='icon'>
              <strong>{row.title.toUpperCase()}</strong>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <Card>
        <HeaderCard title={data?.titulo} setting={true} />
        <CardBody>
          <div className='table-responsive'>
            <Table className='table table-bordernone'>
              <thead>
                <tr>
                  {data?.data?.datasets[0]?.data?.columns?.map((data, index) => {
                    let textAlignClass = data.textAlign ? `text-${data.textAlign}` : '';

                    return (
                      <th className={textAlignClass} key={index}>
                        <span
                          style={{
                            color: primary,
                          }}
                        >
                          {data.title.toUpperCase()}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.data?.datasets[0]?.data.rows.map((data, index) => {
                  const numeroColunas = data.length;

                  return (
                    <tr key={index}>
                      {data[0].iconType && (
                        <td>
                          <div className='d-flex'>
                            <IconColumn row={data[0]} />
                            <div className='flex-grow-1'>
                              <H5>{data[1].title}</H5>
                              <P>{data[1].subtitle}</P>
                            </div>
                          </div>
                        </td>
                      )}
                      {[...Array(numeroColunas).keys()].map((index) => {
                        if (data[0].iconType != null && index <= 1) return;
                        else if (data[0].iconType != null && index === 1) return;

                        let textAlignClass = data[index].textAlign ? `text-${data[index].textAlign}` : '';

                        return (
                          <td className={textAlignClass} key={index}>
                            <H5>{data[index].title}</H5>
                            <P>{data[index].subtitle}</P>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TableChart;
