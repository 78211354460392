import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate, formatNumber } from '../../../../../utils/formatacoes';
import NfsTable from './nfsTable';

const DuesTable = ({ dues }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'identificacao',
      name: 'Identificação',
      selector: (row) => row.identificacao ?? '-',
    },
    {
      id: 'numero',
      name: 'Número',
      selector: (row) => row.numero ?? '-',
    },
    {
      id: 'data_registro',
      name: 'Data Registro',
      selector: (row) => formatDate(row.data_registro) ?? '-',
    },
    {
      id: 'status_id',
      name: 'Situação',
      selector: (row) => row.situacao.descricao ?? '-',
    },
    {
      id: 'moeda',
      name: 'Moeda',
      selector: (row) => (row.moeda ? row.moeda.simbolo + ' - ' + row.moeda.descricao : '-'),
    },
    {
      id: 'valor_total_real',
      name: 'Valor Total R$',
      selector: (row) => formatNumber(row.total_valor_real) ?? '-',
    },
    {
      id: 'total_valor_cv',
      name: 'VMCV',
      selector: (row) => formatNumber(row.total_valor_cv) ?? '-',
    },
    {
      id: 'valor_total_le',
      name: 'VMLE',
      selector: (row) => formatNumber(row.total_valor_le) ?? '-',
    },
    {
      id: 'total_peso_liquido',
      name: 'Peso Líquido',
      selector: (row) => formatNumber(row.total_peso_liquido, 5) ?? '-',
    },
  ];

  useEffect(() => {
    if (!dues) return;

    setRows(dues);
  }, [dues]);

  const DueNfsTable = ({ data }) => {
    return <NfsTable nfs={data.due_nfes} />;
  };

  return (
    <Fragment>
      <DataTable
        dense
        columns={columns}
        data={rows}
        noDataComponent='Nenhum registro encontrado.'
        expandableRows
        expandableRowsComponent={DueNfsTable}
      />
    </Fragment>
  );
};

export default DuesTable;
