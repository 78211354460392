import React, { Fragment } from 'react';
import Rightbar from './RightBar';
import { Row } from 'reactstrap';
import Searchbar from './Search';

const Header = () => {
  return (
    <Fragment>
      <Row className='header-wrapper m-0'>
        <Searchbar />
        <Rightbar />
      </Row>
    </Fragment>
  );
};
export default Header;
