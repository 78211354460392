import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const NfsTable = ({ nfs }) => {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      id: 'chave_acesso',
      name: 'Chave Acesso',
      selector: (row) => row.chave_acesso ?? '-',
    },
    {
      id: 'qtde_itens',
      name: 'Qtde. Itens',
      selector: (row) => row.qtde_itens ?? '-',
    },
    {
      id: 'total_valor_real',
      name: 'Total Valor R$',
      selector: (row) => row.total_valor_real ?? '-',
    },
    {
      id: 'vmcv',
      name: 'VMCV',
      selector: (row) => row.vmcv ?? '-',
    },
    {
      id: 'vmle',
      name: 'VMLE',
      selector: (row) => row.vmle ?? '-',
    },
    {
      id: 'peso_liquido',
      name: 'Peso Líquido',
      selector: (row) => row.peso_liquido ?? '-',
    },
  ];

  useEffect(() => {
    if (!nfs) return;

    let linhas = nfs;

    if (linhas instanceof Object) {
      linhas = Object.keys(nfs).map((key) => nfs[key]);
    }

    setRows(linhas);
  }, [nfs]);

  return (
    <Fragment>
      <DataTable dense columns={columns} data={rows} responsive noDataComponent='Nenhum registro encontrado.' />
    </Fragment>
  );
};

export default NfsTable;
