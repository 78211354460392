import { api } from '../api';

const CadastroProdutosService = {
  index: async (page = 1, limit = 20, sortColumn = null, sortOrder = null, filtros = {}, favoritos = false) => {
    try {
      let url = `cadastro/produtos/index?page=${page}&limit=${limit}`;

      if (sortColumn && sortOrder) {
        url += `&sortColumn=${sortColumn}&sortOrder=${sortOrder}`;
      }

      if (filtros) {
        Object.keys(filtros).forEach((campo) => {
          url += `&${campo}=${filtros[campo]}`;
        });
      }

      if (favoritos) {
        url += `&favoritos=true`;
      }

      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao carregar a listagem de produtos.');
    }
  },
  view: async (id) => {
    try {
      const response = await api.get(`cadastro/produtos/view/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao carregar a visualização do produto.');
    }
  },
  edit: async (id) => {
    try {
      const response = await api.get(`cadastro/produtos/edit/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao carregar a edição do produto.');
    }
  },
  salvar: async (id, formData = null, concluir = 0) => {
    try {
      const response = await api.post(`cadastro/produtos/edit/${id}/${concluir}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      throw new Error('Erro ao carregar a edição do produto.');
    }
  },
  downloadDoc: async (id) => {
    try {
      const response = await api.get(`cadastro/produtos/downloadDoc/${id}`);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao fazer download do arquivo.');
    }
  },
  uploadDoc: async (id, formData) => {
    try {
      const response = await api.post(`cadastro/produtos/upload/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (error) {
      throw new Error('Falha ao fazer o upload');
    }
  },
};

export default CadastroProdutosService;
