import { saveAs } from 'file-saver';

export const downloadFile = (fileData) => {
  const base64 = fileData.file;

  const binaryContent = atob(base64);
  const arrayBuffer = new ArrayBuffer(binaryContent.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryContent.length; i++) {
    uint8Array[i] = binaryContent.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: 'application/pdf' });
  saveAs(blob, fileData.filename);
};
