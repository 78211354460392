import { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';

const InputCpfcnpj = ({ id, handleBlur }) => {
  const [value, setValue] = useState('');

  return (
    <FormControl
      as={ReactInputMask}
      name={id}
      id={id}
      mask={value.length < 15 ? '999.999.999-999' : '99.999.999/9999-99'}
      maskChar=''
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      autoComplete='off'
    />
  );
};

export default InputCpfcnpj;
