import React from 'react';
import { formatDate, formatNumber } from '../../../../../utils/formatacoes';
import LpcosTable from './lpcosTable';
import DuesTable from './duesTable';
import { H3, H4, Span } from '../../../../../Themes/Components/AbstractElements';

const CoresCanaisDi = {
  'SEM CANAL': 'silver',
  VERDE: '#22BAA0',
  LARANJA: '#f2ca4c',
  VERMELHO: '#f25656',
};

const DadosAduaneiros = ({ embarque }) => {
  if (embarque?.model != 'Exportacao.Processos') {
    const embarqueProcesso = embarque?.embarque_processos[0]?.importacao_processo ?? {};

    return (
      <>
        {embarqueProcesso?.via_transporte?.codigo === '01' && (
          <>
            <H3>Dados da Carga</H3>
            <div className='row'>
              <div className='row mb-2'>
                <div className='col-sm-3'>
                  <H4>CE Mercante</H4>
                  <Span>{embarqueProcesso?.carga?.numero_ce ?? '-'}</Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Situação da carga</H4>
                  <Span>
                    {embarque?.situacoes && embarqueProcesso?.carga?.status_id
                      ? embarque?.situacoes[embarqueProcesso?.carga?.status_id]
                      : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Qtde de itens</H4>
                  <Span>{embarqueProcesso?.carga?.quantidade_itens ?? '-'}</Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Peso bruto</H4>
                  <Span>{embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.peso_bruto, 3) : '-'}</Span>
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-sm-3'>
                  <H4>Cubagem</H4>
                  <Span>{embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.cubagem, 3) : '-'}</Span>
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-sm-3'>
                  <H4>Moeda do Frete</H4>
                  <Span>
                    {embarqueProcesso?.carga?.moeda
                      ? `${embarqueProcesso.carga.moeda.codigo} - ${embarqueProcesso.carga.moeda.descricao}`
                      : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Total do Frete</H4>
                  <Span>
                    {embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.frete_total_moeda, 3) : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Frete Básico</H4>
                  <Span>
                    {embarqueProcesso?.carga ? formatNumber(embarqueProcesso.carga.frete_basico_moeda, 3) : '-'}
                  </Span>
                </div>
                <div className='col-sm-3'>
                  <H4>Pagamento</H4>
                  <Span>
                    {console.log(embarqueProcesso?.carga?.frete_forma_pagamento)}
                    {{ P: 'PREPAID (PAGO)', C: 'COLLECT', '': '-' }[embarqueProcesso.carga.frete_forma_pagamento] ??
                      '-'}
                  </Span>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='row m-t-20'>
          <H3>Dados Aduaneiros</H3>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>Número da DI</H4>
              <Span>{embarqueProcesso?.numero_registro ?? '-'}</Span>
            </div>
            <div className='col-sm-3'>
              <H4>Data de Registro</H4>
              <Span>{embarqueProcesso?.data_registro ? formatDate(embarqueProcesso.data_registro) : '-'}</Span>
            </div>
            <div className='col-sm-3'>
              <H4>Situação</H4>
              <Span>{embarqueProcesso?.processo_situacao?.descricao ?? '-'}</Span>
            </div>
            <div className='col-sm-3'>
              <H4>Canal</H4>
              <Span>
                {embarqueProcesso?.canal ? (
                  <i
                    className='icofont icofont-info-circle'
                    style={{ color: CoresCanaisDi[embarqueProcesso.canal] }}
                  ></i>
                ) : (
                  '-'
                )}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>Unidade RFB</H4>
              <Span>
                {embarqueProcesso?.unidade_localizacao_rfb
                  ? `${embarqueProcesso.unidade_localizacao_rfb.codigo} - ${embarqueProcesso.unidade_localizacao_rfb.descricao}`
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Recinto Aduaneiro</H4>
              <Span>
                {embarqueProcesso?.armazem_recinto_rfb
                  ? `${embarqueProcesso.armazem_recinto_rfb.codigo} - ${embarqueProcesso.armazem_recinto_rfb.descricao}`
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Peso Líquido</H4>
              <Span>
                {embarqueProcesso?.carga_peso_liquido ? formatNumber(embarqueProcesso.carga_peso_liquido, 5) : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Peso Bruto</H4>
              <Span>
                {embarqueProcesso?.carga_peso_bruto ? formatNumber(embarqueProcesso.carga_peso_bruto, 5) : '-'}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>VMCV</H4>
              <Span>
                {embarqueProcesso?.total_vmcv_moeda
                  ? formatNumber(embarqueProcesso.total_vmcv_moeda, 2, `${embarqueProcesso.moeda?.simbolo ?? ''} `)
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>VMLE</H4>
              <Span>
                {embarqueProcesso?.total_vmle_moeda
                  ? formatNumber(embarqueProcesso.total_vmle_moeda, 2, `${embarqueProcesso.moeda?.simbolo ?? ''} `)
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Frete</H4>
              <Span>
                {embarqueProcesso?.frete_total_dolar
                  ? formatNumber(embarqueProcesso.frete_total_dolar, 2, 'USD ')
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>Seguro</H4>
              <Span>
                {embarqueProcesso?.seguro_total_moeda
                  ? formatNumber(
                      embarqueProcesso.seguro_total_moeda,
                      2,
                      `${embarqueProcesso.seguro_moeda?.simbolo ?? ''} `,
                    )
                  : '-'}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>II</H4>
              <Span>
                {embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                  ? formatNumber(embarqueProcesso.processo_itens[0]?.ii_vlr_devido, 2, 'R$ ')
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>IPI</H4>
              <Span>
                {embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                  ? formatNumber(embarqueProcesso.processo_itens[0]?.ipi_vlr_devido, 2, 'R$ ')
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>PIS</H4>
              <Span>
                {embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                  ? formatNumber(embarqueProcesso.processo_itens[0]?.pis_vlr_devido, 2, 'R$ ')
                  : '-'}
              </Span>
            </div>
            <div className='col-sm-3'>
              <H4>COFINS</H4>
              <Span>
                {embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                  ? formatNumber(embarqueProcesso.processo_itens[0]?.cofins_vlr_devido, 2, 'R$ ')
                  : '-'}
              </Span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-3'>
              <H4>ICMS</H4>
              <Span>
                {embarqueProcesso?.processo_itens && embarqueProcesso?.processo_itens[0]
                  ? formatNumber(embarqueProcesso.processo_itens[0]?.icms_valor_devido, 2, 'R$ ')
                  : '-'}
              </Span>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className='row'>
          <div className='col-sm-12'>
            <H3>LPCOs</H3>
            <LpcosTable situacoes={embarque?.situacoesLpco} lpcos={embarque?.embarque_lpcos} />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <H3>DU-Es</H3>
            <div className='table-responsive'>
              <DuesTable dues={embarque?.embarque_dues} />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default DadosAduaneiros;
