import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Dropzone from 'react-dropzone-uploader';
import UploadPreview from './uploadPreview';

import 'react-dropzone-uploader/dist/styles.css';

const UploadButton = ({ id, options = [], callback }) => {
  const [showUpload, setShowUpload] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleClose = () => {
    setShowUpload(false);
  };

  const handleSubmit = (files) => {
    const filesWithOptions = files.map((file) => ({
      ...file,
      tipoDocumento: selectedOptions[file.meta.id],
    }));

    callback(id, filesWithOptions);
  };

  const handleOptionChange = (fileId, option) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [fileId]: option,
    }));
  };

  return (
    <Fragment>
      <Button color='primary' onClick={() => setShowUpload(true)}>
        Anexar Arquivo
      </Button>

      <Modal isOpen={showUpload} size='lg'>
        <ModalHeader>Arquivos</ModalHeader>

        <ModalBody>
          <Dropzone
            onSubmit={handleSubmit}
            PreviewComponent={(props) => (
              <UploadPreview
                {...props}
                onOptionChange={handleOptionChange}
                selectedOption={selectedOptions[props.meta.id]}
                options={options}
              />
            )}
            inputContent='Arraste e solte os arquivos ou clique para selecionar'
            submitButtonContent='Enviar'
            inputWithFilesContent='Adicionar Arquivos'
          />
        </ModalBody>

        <ModalFooter>
          <Button color='primary' onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default UploadButton;
