import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import PageLoader from '../../../../Themes/Layout/PageLoader';
import { Breadcrumbs, H3, H4, Span } from '../../../../Themes/Components/AbstractElements';
import { formatDate } from '../../../../utils/formatacoes';
import TrackingEmbarquesService from '../../../../Services/Tracking/TrackingEmbarquesService';
import ConteineresTable from './components/conteineresTable';
import DadosAduaneiros from './components/dadosAduaneiros';
import TimelineComponent from './components/timeline';

const TrackingEmbarquesView = () => {
  const [embarque, setEmbarque] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const icons = {
    1: <i className='icofont icofont-ship' style={{ fontSize: 50 }} title='Marítima' />,
    4: <i className='icofont icofont-airplane-alt' style={{ fontSize: 50 }} title='Área' />,
  };

  useEffect(() => {
    const view = async () => {
      try {
        const data = await TrackingEmbarquesService.view(id);

        setEmbarque(data);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        navigate('/tracking/embarques');
      }
    };

    view();
  }, [id]);

  return (
    <>
      <PageLoader loaded={loading} />
      <Breadcrumbs
        mainTitle={`Embarques ${embarque?.nome ?? ''}`}
        title='Embarques'
        parent='Tracking'
        model={'Tracking.Embarques'}
        processoId={id}
        favorito={embarque?.favorito ?? false}
      />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-10'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row mb-2'>
                      <div className='col-sm-1'>{icons[embarque?.tipo_embarque_id] ?? '-'}</div>

                      <div className='col-sm-2'>
                        <H4>Situação</H4>
                        <Span>
                          {embarque?.situacao ? <span className='badge badge-primary'>{embarque?.situacao}</span> : '-'}
                        </Span>
                      </div>

                      <div className='col-sm-2'>
                        <H4>{embarque?.tipo_documento}</H4>
                        <Span>{embarque?.numero + (embarque?.numero_house ? ' - ' + embarque?.numero_house : '')}</Span>
                      </div>
                      <div className='col-sm-4'>
                        <H4>{embarque?.model === 'Exportacao.Processos' ? 'Exportador' : 'Consignatário'}</H4>
                        <Span>{embarque?.pessoa.cpfcnpj + ' - ' + embarque?.pessoa.fantasia ?? '-'}</Span>
                      </div>
                      <div className='col-sm-3'>
                        <H4>Processo(s)</H4>
                        <Span>
                          {embarque?.embarque_processos?.length > 0
                            ? embarque?.embarque_processos.map((embarqueProcesso) => {
                                let processo;
                                let rota;
                                switch (embarque.model) {
                                  case 'Exportacao.Processos':
                                    processo = embarqueProcesso.exportacao_processo;
                                    rota = 'exportacao/processos';
                                    break;
                                  case 'Importacao.Processos':
                                    processo = embarqueProcesso.importacao_processo;
                                    rota = 'importacao/processos';
                                    break;
                                  default:
                                    processo = null;
                                }

                                if (processo) {
                                  return (
                                    <a
                                      key={embarqueProcesso.processo_id}
                                      href={`/${rota}/view/${embarqueProcesso.processo_id}`}
                                      title='Clique para visualizar o processo.'
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      style={{ marginRight: '5px' }}
                                    >
                                      {processo.numero}{' '}
                                      <i className='icofont icofont-external-link' aria-hidden='true'></i>
                                    </a>
                                  );
                                }

                                return null;
                              })
                            : 'Não há processos vinculados.'}
                        </Span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Linha do tempo</H3>
                    <div className='row mb-2'>
                      <TimelineComponent embarque={embarque} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <H3>Contêineres</H3>
                    <ConteineresTable conteineres={embarque?.embarque_contentores} />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <DadosAduaneiros embarque={embarque} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-2'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row mb-2'>
                      <div className='col-sm-12' style={{ display: 'flex', flexDirection: 'column' }}>
                        <H4>
                          <i className='icofont icofont-location-pin' style={{ fontSize: 16 }} title='Origem' /> ORIGEM
                        </H4>
                        <Span>País: {embarque?.pais_origem?.nome ?? '-'}</Span>
                        <Span>Porto: {embarque?.porto_origem?.nome ?? '-'}</Span>
                      </div>
                      <div className='col-sm-12 m-t-15' style={{ display: 'flex', flexDirection: 'column' }}>
                        <H4>
                          <i className='icofont icofont-location-pin' style={{ fontSize: 16 }} title='Destino' />{' '}
                          DESTINO
                        </H4>
                        <Span>País: {embarque?.pais_destino?.nome ?? '-'}</Span>
                        <Span>Porto: {embarque?.porto_descarga?.nome ?? '-'}</Span>
                      </div>
                      <div className='col-sm-12' style={{ display: 'flex', flexDirection: 'column' }}>
                        <hr className='m-t-15' />
                      </div>
                      <div className='col-sm-12'>
                        <H4>
                          <i className='icofont icofont-ui-calendar' style={{ fontSize: 16 }} title='ETD' /> ETD
                        </H4>
                        <Span>{formatDate(embarque?.previsao_partida) ?? '-'}</Span>
                      </div>
                      <div className='col-sm-12 m-t-15'>
                        <H4>
                          <i className='icofont icofont-ui-calendar' style={{ fontSize: 16 }} title='ETA' /> ETA
                        </H4>
                        <Span>{formatDate(embarque?.previsao_chegada_armador) ?? '-'}</Span>
                      </div>
                      <div className='col-sm-12 m-t-15'>
                        <H4>
                          <i className='icofont icofont-user' style={{ fontSize: 15 }} title='Armador' /> Armador
                        </H4>
                        <Span>{embarque?.armador?.nome ?? '-'}</Span>
                      </div>
                      <div className='col-sm-12 m-t-15'>
                        <H4>
                          <i className='icofont icofont-ship' style={{ fontSize: 15 }} title='Navios' /> Navios
                        </H4>
                        <Span>
                          {embarque?.embarque_navios &&
                            embarque.embarque_navios.map((navio) => (
                              <div
                                className='col-sm-12'
                                key={navio.id}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  color: navio.id === embarque.navio_atual_id ? '#f2ca4c' : '#22BAA0',
                                }}
                              >
                                <Span>{navio.navio.nome}</Span>
                                <Span>
                                  <i className='icofont icofont-long-arrow-right'></i> Código IMO:{' '}
                                  {navio.navio.codigo_imo ?? '-'}
                                </Span>
                                <Span>
                                  <i className='icofont icofont-long-arrow-right'></i> Viagem: {navio.viagem ?? '-'}
                                </Span>
                              </div>
                            ))}
                          {!embarque?.embarque_navios && <Span>-</Span>}
                        </Span>
                      </div>
                      <div className='col-sm-12' style={{ display: 'flex', flexDirection: 'column' }}>
                        <hr className='m-t-15' />
                      </div>
                      <div className='col-sm-12'>
                        <H4>
                          <i className='icofont icofont-plus-circle' style={{ fontSize: 15 }} title='Data Cadastro' />{' '}
                          Data Cadastro
                        </H4>
                        <Span>{formatDate(embarque?.data_cadastro) ?? '-'}</Span>
                      </div>
                      <div className='col-sm-12 m-t-15'>
                        <H4>
                          {' '}
                          <i
                            className='icofont icofont-refresh'
                            style={{ fontSize: 15 }}
                            title='Última Atualização'
                          />{' '}
                          Última Atualização
                        </H4>
                        <Span>{formatDate(embarque?.ultima_atualizacao) ?? '-'}</Span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingEmbarquesView;
